import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="pull-right hidden-xs" data-toogle="tooltip" title="we can do it, you can't do it"
          style={{ cursor: "pointer" }}>
          by Divisi Experience <b>Expert</b>
        </div>
        <strong>Copyright © 2020 <Link to="/">Institut Asia Malang</Link>.</strong>
      </footer>
    )
  }
}
