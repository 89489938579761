import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'
import V_EditSistemMenu from './V_EditSistemMenu'

export default class EditSistemMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      level_name: '',
      filedatas: '',
      dataplikasi: [],
      aplikasi_id: '',
    }
  }

  // handleInputChange = (event) => {
  //   event.preventDefault()
  //   // console.log(event)
  //   // console.log(event.target.name)
  //   // console.log(event.target.value)
  //   this.setState({
  //     [event.target.name]: event.target.value
  //   })
  // }

  // handleSubmit = async (event) => {
  //   event.preventDefault()
  //   await API.post('menu/sistem/administrator/add' + key.baseURL, this.state)
  //     .then(Response =>
  //       this.setState({
  //         filedatas: Response.data[0].filedatas
  //       })
  //     )

  //   if (this.state.filedatas == 1) {
  //     alert("Berhasil !!")
  //     window.location.replace('/administrator/sistemmenu')
  //   } else {
  //     alert("Gagal Membuat Surat")
  //     window.location.reload()
  //   }

  // }

  async AplikasiMenu() {
    const level_id = this.props.match.params.level_id
    await API.get("aplikasi/admin/wkwk/view" + key.baseURL + '&idacc=' + level_id)
      .then(Response => this.setState({
        dataplikasi: Response.data
      }))
    // console.log(this.state.dataplikasi)
  }

  async componentDidMount() {

    this.timer = setInterval(() => this.sesscek(), 1000);
    this.AplikasiMenu()
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  async tambah() {
    const level_id = this.props.match.params.level_id
    await API.get("aktifkan/aps/menusis/add" + key.baseURL + '&level_id=' + level_id)
      .then(Response => this.setState({
        dataplikasi: Response.data
      }))
  }

  simpanbos() {
    alert('Berhasil Simpan !!')
    window.location.href = "/administrator/sistemmenu/level"
  }

  render() {

    const renderData = this.state.dataplikasi.map(dataplikasi => {
      return (
        <V_EditSistemMenu v_editSistemMenu={dataplikasi} key={dataplikasi.aplikasi_id} />
      )
    })

    return (
      <div>
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              Rule Menu Akses
        </h1>
          </section>
          {/* Main content */}
          <section className="content" style={{ paddingRight: '30px' }}>
            <div className="row">
              <div className="col-md-6 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Kelola Rule Akses</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="col-md-12">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="col-md-8 thku" style={{ textAlign: 'center' }} scope="col">Aplikasi</th>
                            <th className="col-md-5 thku" scope="col" style={{ textAlign: 'center' }}>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData}
                          {/* {
                          this.state.dataplikasi.map(dataplikasi => {
                            return (
                              <tr>
                                <th>{dataplikasi.nama_aplikasi}</th>
                                <th style={{ textAlign: 'center' }}>
                                  {buttons(dataplikasi.btn)}
                                </th>
                              </tr>
                            )
                          })
                        } */}
                        </tbody>
                      </table>
                      <div className="row" style={{ marginBottom: '10px' }}>
                        <div className="col-md-6">
                          <div>
                            <Link to="/administrator/sistemmenu/level" className="btn btn-warning">Kembali</Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-right">
                            <button onClick={this.simpanbos} className="btn btn-success">Simpan</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.box-body */}
                </div>
              </div>
            </div>
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
