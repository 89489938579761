import React, { Component } from "react";
import V_KelolaUser from "./V_KelolaUser";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import key from "../../axios/koneksi";
import API from "../../axios/Api";

export default class KelolaUser extends Component {
  state = {
    kelolauser: [],
  };

  async componentDidMount() {
    // await API.get("user_entiti/lv/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
    await API.get("kll/pngg/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt").then(
      (Response) =>
        this.setState({
          kelolauser: Response.data.map((val, idx) => ({
            No: idx + 1,
            ...val,
          })),
        })
    );
    // console.log(this.state.kelolauser)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace("/loadingout");
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace("/loadingout");
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  // async handleGen() {
  //   await API.put('administrator/regenpass/up?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt'+ key.baseURL)
  //     .then(Response => this.setState({
  //       kelolauser: Response.data.filedatas
  //     }))
  // }

  // handleGen = () =>{
  //   this.regenPass()
  // }

  onSearchTable = () => {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[2];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  render() {
    const renderData = this.state.kelolauser.map((kelolauser) => {
      return <V_KelolaUser v_kelolauser={kelolauser} key={kelolauser.id} />;
    });

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Kelola User</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>Daftar User</strong>
                    <div className="box-tools pull-right"></div>
                  </div>
                  {/* /.box-header */}

                  <div className="box-body">
                    <form
                      method="post"
                      action={`${key.baseURL}administrator/regenpass/up?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt`}
                    >
                      <div className="table-responsive">
                        <div className="col-md-12">
                          <input type="text" className="form-control mb-3" id="myInput" onKeyUp={this.onSearchTable} placeholder="Cari data nama pengguna.." />
                          <table
                            id="myTable"
                            className="table table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>User ID</th>
                                <th>User Name</th>
                                {/* <th>Create at</th>
                                    <th>Updated at</th> */}
                                <th>Password Default</th>
                                {/* <th>NIDN</th> */}
                                {/* <th>Tools</th> */}
                                <th>Grup</th>
                                <th>Re-Generate</th>
                                {/* <th>Opsi</th> */}
                              </tr>
                            </thead>
                            <tbody>{renderData}</tbody>
                            <tfoot>
                              <tr>
                                <th>No</th>
                                <th>User ID</th>
                                <th>User Name</th>
                                <th>Password Default</th>
                                <th>Grup</th>
                                <th>Re-Generate</th>
                                {/* <th>Opsi</th> */}
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div
                        className="col-md-12 text-right"
                        style={{ marginTop: "2.5%", marginBottom: "1%" }}
                      >
                        <button type="submit" className="btn btn-warning">
                          Re-Generate
                        </button>{" "}
                        &nbsp;
                        <Link
                          to="/administrator/tambahuser"
                          className="btn btn-primary"
                        >
                          Tambah
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    );
  }
}
// action={`${config.baseURL}administrator/regenpass/up?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt`}
