import React from 'react'
import API from '../../axios/Api'
import key from '../../axios/key'
import { Link } from 'react-router-dom'


function V_aplikasimenu({ V_appmenu }) {

  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {
    await API.put('aplikasi/di/hapus/sungguhan/dl' + key.baseURL + '&aplikasi_id=' + V_appmenu.aplikasi_id)
    window.location.reload()
  }
  function pop() {
    var pop = window.confirm('Yakin ingin menghapus?')
    if (pop == true) {
      handleClick()
    } else {
      // window.location.reload()
    }
  }
  async function actf() {
    await API.put('/aktfikan/aplikasi/mantab/dl' + key.baseURL + '&aplikasi_id=' + V_appmenu.aplikasi_id)
    window.location.reload()
  }

  const sts = (val) => {
    if (val === "1") return <td style={{ textAlign: 'center' }}>
      <Link to={'/administrator/editappmenu/' + V_appmenu.aplikasi_id} style={{ marginRight: '5px' }} className="btn btn-warning">Edit</Link>
      <button onClick={pop} className="btn btn-danger">Delete</button>
    </td>
    else return <td style={{ textAlign: 'center' }}>
      <button onClick={actf} className="btn btn-success">Aktifkan</button>
    </td>
  }

  return (
    <tr>
      <td>{V_appmenu.No}</td>
      <td>{V_appmenu.nama_aplikasi}</td>
      <td>{V_appmenu.v_aplikasi}</td>
      {sts(V_appmenu.id_hidden)}
    </tr>
  )
}

export default V_aplikasimenu;