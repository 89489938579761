import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'

export default class LogBAA extends Component {


  constructor(props) {
    super(props)
    this.state = {
      level_id: ''
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('create_recieve.php', this.state)
    this.props.history.push('/beranda')
    // const data = this.state
    // console.log("Data berhasil", data)
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
  }


  componentDidMount() {
    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {
    return (
      <form name="" action="" method="">
        <div className="col-md-12 text-bold" style={{ marginBottom: '1%', marginTop: '3%' }}>
          <label>Pilih tanggal backup Log :</label>
        </div>

        <div className="col-md-12" style={{ marginBottom: '5%' }}>
          <div className="col-md-3">
            <p>dari tanggal</p>
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-calendar"></i>
              </div>
              <input type="date" name="" className="form-control" onChange={this.handleInputChange} placeholder="Sub Menu"></input>
            </div>
          </div>

          <div className="col-md-1">
            <br />
            <img
              style={{ width: '95%', opacity: '0.5', marginTop: '17px' }}
              src="https://i.ibb.co/WGtF4vt/arrow.png" />
          </div>

          <div className="col-md-3">
            <p>sampai tanggal</p>
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-calendar"></i>
              </div>
              <input type="date" name="" className="form-control" onChange={this.handleInputChange} placeholder="Sub Menu"></input>
            </div>
          </div>

          <div className="col-md-3" style={{ marginTop: '24px' }}>
            <button className="btn btn-primary">BACKUP LOG</button>
          </div>
        </div>
      </form>
    )
  }
}
