import React, { Component } from 'react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'

class T_Struktural extends Component {
    constructor() {
        super()
        this.state = {
            struktural: [],
            usrentiti: [],
            filedatas: '',
            nidn_rektor: '',
            rektor_name: '',
            rektor_id: '',
            ketkode_rektor: '',
            p1_rekto: '',
            p2_rekto: '',
            id: '',
        }
    }

    async editstruktural() {
        const idus = this.props.match.params.id_rektor
        const res = await API.get('struktural/saben/idr/view' + key.baseURL + '&id_rektor=' + idus)
        this.setState({
            nidn_rektor: res.data.nidn_rektor,
            rektor_name: res.data.rektor_name,
            rektor_id: res.data.rektor_id,
            ketkode_rektor: res.data.ketkode_rektor,
            p1_rekto: res.data.p1_rekto,
            p2_rekto: res.data.p2_rekto,
            id: res.data.id,
        })
        console.log(res.data)
    }
    async dropdownuser() {
        await API.get('dropdown/usrentiti/combo/{v}' + key.baseURL)
            .then(Response => this.setState({
                usrentiti: Response.data
            }))
        //   console.log(this.state.usrentiti)
    }

    handleInputChange = (event) => {
        event.preventDefault()
        // console.log(event)
        // console.log(event.target.name)
        // console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        const idus = this.props.match.params.id_rektor
        await API.post('struktural/edit/jabatan/ls/up' + key.baseURL + '&id_rektor=' + idus, this.state)
            .then(Response =>
                this.setState({
                    filedatas: Response.data[0].filedatas
                })
            )

        if (this.state.filedatas == 1) {
            alert("Berhasil !!")
            window.location.replace('/administrator/strukturaljabatan')
        } else {
            alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
        }
    }
    componentDidMount() {
        this.editstruktural();
        this.dropdownuser();
        this.timer = setInterval(() => this.sesscek(), 1000);
    }
    sesscek() {
        if (sessionStorage.getItem("adm") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        } else if (localStorage.getItem("logus") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        }
    }
    componentWillUnmount() {
        this.timer = null;
    }

    render() {
        return (
            <div>
                <Menu />
                <Header />
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <h1>Edit Struktural</h1>
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 margin-bottom-30">
                                <div className="box box-primary box-solid">
                                    <div className="box-header">
                                        <strong>Form Edit Struktural</strong>
                                        <div className="box-tools pull-right">
                                        </div>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="box-body">
                                        <form onSubmit={this.handleSubmit}>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>NIDN</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <input name="nidn_rektor" defaultValue={this.state.nidn_rektor} className="form-control" required placeholder="NIDN" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>NAMA LENGKAP</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <input name="rektor_name" defaultValue={this.state.rektor_name} className="form-control" required placeholder="Nama Lengkap" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>User / Pengguna</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <select name="id" className="form-control" required placeholder="-- pilih jabatan --" onChange={this.handleInputChange}>
                                                            <option value={this.state.id} selected hidden>{this.state.rektor_name}</option>
                                                            {this.state.usrentiti.map((data, idx) => {
                                                                return (
                                                                    <option value={data.id} key={idx}>{data.user_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>JABATAN</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-lock"></i>
                                                        </div>
                                                        <select name="rektor_id" className="form-control" required placeholder="-- pilih jabatan --" required onChange={this.handleInputChange}>
                                                            <option value={this.state.rektor_id} selected hidden>{this.state.rektor_id}</option>
                                                            <option value="R.0">[R.0] Rektor</option>
                                                            <option value="R.1">[R.1] Wakil Rektor I</option>
                                                            <option value="R.2">[R.2] Wakil Rektor II</option>
                                                            <option value="R.3">[R.3] Wakil Rektor III</option>
                                                            <option value="R.4">[R.4] Wakil Rektor IV</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>KETERANGAN JABATAN</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input name="ketkode_rektor" defaultValue={this.state.ketkode_rektor} className="form-control" required placeholder="Keterangan Jabatan" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>MASA JABATAN MULAI</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input type="date" name="p1_rekto" defaultValue={this.state.p1_rekto} className="form-control" required placeholder="Masa Jabatan Mulai" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>MASA JABATAN AKHIR</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input type="date" name="p2_rekto" defaultValue={this.state.p2_rekto} className="form-control" required placeholder="Masa Jabatan Akhir" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 text-right" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <Link to="/administrator/strukturaljabatan" className="btn btn-warning">Kembali</Link> &nbsp;
                                                <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </section>
                    {/* /.content */}
                </div>
                <Footer />
            </div>
        );
    }
}

export default T_Struktural;