import React from 'react'
import { Link } from 'react-router-dom'


function V_KelolaMenuSystem({V_listakses}){

    const script = document.createElement("script");

    script.src ='../js/content.js';
    script.async = true;

    document.body.appendChild(script);

    function hapusakses(){
        var pop = window.confirm('Apakah Anda Yakin ingin Menghapus Hak Akses?');
        if(pop == true){
        }else{
        window.location.reload();
        }
    }

    const status = (val) => {
        val=V_listakses.No-1
      }

    return(
      <tr>
        <td>{V_listakses.No}</td>
        <td>{V_listakses.user_name}</td>
        <td>{V_listakses.divisi_name}</td>
        <td>
            <div className="card-body">
                <div className="alert alert-danger alert-dismissible col-md-4" style={{ width: '150px', marginRight: '10px' }}>
                    <button onClick={hapusakses} type="button" className="close" data-dismiss="alert" aria-hidden="true" style={{ color: 'white' }}>×</button>
                    <h5>{V_listakses.level_id}</h5>
                </div>&nbsp;
                <div className="alert alert-danger alert-dismissible col-md-4" style={{ width: '150px' }}>
                    <button onClick={hapusakses} type="button" className="close" data-dismiss="alert" aria-hidden="true" style={{ color: 'white' }}>×</button>
                    <h5>{V_listakses.level_id}</h5>
                </div>
            </div>
        </td>
        <td>
            <Link to={'/administrator/aksesuser/' + V_listakses.id} className="btn btn-warning">Edit</Link>
        </td>
    </tr>
    )
} 
export default V_KelolaMenuSystem