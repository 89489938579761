import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import V_ListAkses from './V_KelolaMenuSystem'
import keyastor from '../../axios/key'

export default class KelolaMenuSystem extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Recieve_code: '',
      Recieve_number: '',
      akses: [],


    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('', this.state)
    this.props.history.push('/kelolamenusystem')
    // const data = this.state
    // console.log("Data berhasil", data)
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
  }

  componentDidMount() {
    this.dataakses();
  }
  async dataakses() {
    // const urt = this.props.match.params.id_urt
    await API.get("grub/menu/view" + keyastor.baseURL)
      .then(Response => this.setState({
        akses: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))
    // console.log(this.state.akses)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {
    const renderData = this.state.akses.map(akses => {
      return (
        <V_ListAkses V_listakses={akses} key={akses.id} />
      )
    })

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              Kelola Menu System
                </h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Kelola Menu System</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table id="example1" className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Nama</th>
                            <th>Divisi</th>
                            <th>Akses</th>
                            <th>Opsi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
