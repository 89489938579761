import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import V_akses from './V_akses'
import key from '../../axios/key'

export default class EditDataAkses extends Component {

  constructor(props) {
    super(props)
    this.state = {
      objek: [],
      level_list: []
    }
  }
  async componentDidMount() {
    await API.get('/administrator/level/adit/akses/all/v' + key.baseURL)
      .then(Response => this.setState({
        level_list: Response.data
      }))

    // console.log(this.state.level_list)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }


  render() {
    const renderData = this.state.level_list.map(level_list => {
      return (
        <V_akses v_akses={level_list} key={level_list.level_id} />
      )
    })
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Edit Hak Akses</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Edit Hak Akses</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form method="POST" action="" encType="multipart/form-data">
                      <div className="form-group-row">
                        {/* start form group */}

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Username</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-user"></i>
                              </div>
                              <input name="no_suratkeluar" value="" readOnly required className="form-control" onChange={this.handleInputChange} placeholder="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Divisi</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bars"></i>
                              </div>
                              <input name="hal_suratkeluar" value="" readOnly required className="form-control" onChange={this.handleInputChange} placeholder="" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Akses Pengguna</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bars"></i>
                              </div>
                              <select name="" className="form-control" onChange={this.handleInputChange} placeholder="Select Group Menu System" >
                                <option value="">==Akses Pengguna==</option>
                                {renderData}
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* end form group */}
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <Link to="/administrator/kelolamenusystem" className="btn btn-danger">Kembali</Link>
                        <button type="submit" value="Edit" className="btn btn-primary" style={{ marginLeft: '10px', width: '70px' }}>Kirim</button>&nbsp;
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>

    )
  }
}
