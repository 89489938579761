import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Menu extends Component {
  constructor() {
    super()
    this.state = {
      namau: ''
    }
  }

  render() {
    return (
      <aside className="main-sidebar">
        {/* sidebar: style can be found in sidebar.less */}
        <section className="sidebar">

          {/* Sidebar user panel */}
          <div className="user-panel">
            <div className="pull-left image">
              <img src="../assets/images/asia.png" className="img-circle" alt="User" />
            </div>
            <div className="pull-left info">
              <p style={{ color: 'white' }}>{sessionStorage.getItem("nama")}</p>
            </div>
          </div>
          {/* sidebar menu: : style can be found in sidebar.less */}

          <ul className="sidebar-menu" data-widget="tree">
            <li className="header">Navigasi Menu</li>

            {/*Home*/}
            <li><Link to="/administrator/beranda"><i className="fa fa-home" /> <span>Home</span></Link></li>
            {/*Home*/}

            {/*User*/}
            {/* <li><Link to="/administrator/kelolauser"><i className="fa fa-user" /> <span>Kelola Pengguna</span></Link></li> */}
            {/* user  */}

            {/*Menu*/}
            <li className="active treeview menu-open">
              <Link to="#">
                <i className="fa fa-list" /> <span>Sistem Menu</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/administrator/appmenu"><i className="fa fa-window-maximize" /> Aplikasi</Link></li>
                <li><Link to="/administrator/sistemmenu/level"><i className="fa fa-gears" /> Menu</Link></li>
                {/* <li><Link to="/administrator/groupmenu"><i className="fa fa-group" /> Group</Link></li>
                  <li><Link to="/administrator/submenu"><i className="fa fa-sitemap" /> Sub</Link></li> */}
              </ul>
            </li>
            {/*Menu*/}

            <li className="active treeview menu-open">
              <Link to="#">
                <i className="fa fa-users" /> <span>Kelola Akses</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/administrator/kelolauser"><i className="fa fa-user" /> <span>Kelola Pengguna</span></Link></li>
                {/* <li><Link to="/administrator/usergroup"><i className="fa fa-sliders" />User Grup</Link></li> */}
                <li><Link to="/administrator/usergroup2"><i className="fa fa-sliders" />User Grup</Link></li>
                {/* <li><Link to="/administrator/useraccess"><i className="fa fa-sliders" />User Access</Link></li> */}
                {/* <li><Link to="/administrator/kelolamenusystem"><i className="fa fa-tasks" />Group Menu</Link></li> */}
                {/* <li><Link to="/administrator/kelolasubmenu"><i className="fa fa-sliders" />Rule Menu Akses</Link></li> */}
              </ul>
            </li>

            <li className="active treeview menu-open">
              <Link to="#">
                <i className="fa fa-sitemap" /> <span>Struktural</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/administrator/strukturaljabatan"><i className="fa fa-user-circle" /> <span>Jabatan</span></Link></li>
              </ul>
            </li>

            {/*Kelola Log*/}
            <li className="active treeview menu-open">
              <Link to="#">
                <i className="fa fa-database" /><span> Kelola LOG</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </Link>
              <ul className="treeview-menu">
                <li><Link to="/administrator/logsurat"><i className="fa fa-envelope" /> Log Surat</Link></li>
                <li><Link to="/administrator/logbau"><i className="fa fa-money" /> Log Keuangan</Link></li>
                <li><Link to="/administrator/logbaa"><i className="fa fa-graduation-cap" /> Log Akademik</Link></li>
              </ul>
            </li>
            {/*Kelola Log*/}

            {/*Kelola Rule*/}
            {/* <li><Link to="/administrator/kelolarule"><i className="fa fa-gears" /><span> Kelola Rule</span></Link></li> */}
            {/*Kelola Rule*/}

            {/*LogOut*/}
            <li><Link to="/loadingout"><i className="fa fa-sign-out" /><span> Log Out</span></Link></li>
            {/*LogOut*/}
          </ul>

        </section>
        {/* /.sidebar */}
      </aside>
    )
  }
}
