import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditAplikasiMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      objek: [],
      menu_name: '',
      aplikasi_id: '',
      sub_menu_id: '',
      menu_id: '',
      link: '',
      gmenu: '',
    }
  }

  async grpmenu() {
    await API.get("menu/grub/caca/lv/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
      .then(Response => this.setState({
        objek: Response.data
      }))
    // console.log(this.state.objek)
  }
  async grpmenu2() {
    const idapk = this.props.match.params.menu_id
    const res = await API.get('/sub/di/idu/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&menu_id=' + idapk)
    this.setState({
      aplikasi_id: res.data[0].aplikasi_id,
      link: res.data[0].link,
      menu_name: res.data[0].menu_name,
      sub_menu_id: res.data[0].sub_menu_id,
      gmenu: res.data[0].gmenu,
    })
    // console.log(res.data)
  }
  componentDidMount() {
    this.grpmenu();
    this.grpmenu2();
  }
  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const idapk = this.props.match.params.menu_id
    await API.post('sub/menu/lw/update/up' + key.baseURL + '&menu_id=' + idapk, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/sistemmenu/sub')
    } else {
      alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
    }

  }

  render() {

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Sub Menu System</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Sub Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form method="POST" action="http://192.168.2.181/apinew/public/menu/sub/astor/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group-row">
                        {/* start form group */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Nama Sub menu</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bars"></i>
                              </div>
                              <input name="menu_name" defaultValue={this.state.menu_name} className="form-control" required placeholder="Nama Sub Menu" onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Link Menu</label>
                            <div className="input-group">
                              <div className="input-group-addon"><i className="fa fa-external-link"></i>
                              </div>
                              <input name="link" defaultValue={this.state.link} className="form-control" required placeholder="Link Menu" onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Group menu</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-user"></i>
                              </div>
                              <select name="sub_menu_id" className="form-control" placeholder="-- pilih group menu --" required onChange={this.handleInputChange}>
                                <option defaultValue={this.state.sub_menu_id} selected hidden>{this.state.gmenu}</option>
                                {
                                  this.state.objek.map(objek => {
                                    return <option value={objek.menu_id}>{objek.menu_name}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* end form group */}
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <Link to="/administrator/sistemmenu/sub" className="btn btn-danger" style={{ marginRight: '5px' }}>Kembali</Link>
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>&nbsp;
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
