import React, { Component } from 'react'


function V_LogBAU({ v_logbau }) {

    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);


    // componentDidMount (){
    //     const script = document.createElement("script");

    //     script.src ='../js/content.js';
    //     script.async = true;

    //     document.body.appendChild(script);
    // }

    // render() {
    return (
        <tr>
            <td>{v_logbau.Log_id}</td>
            <td>{v_logbau.User_name}</td>
            <td>{v_logbau.Log_date}</td>
            <td>{v_logbau.Log_date}</td>
            <td>{v_logbau.Log_action}</td>
        </tr>
    )
}
export default V_LogBAU
