import React, { Component } from 'react'
import { Link } from 'react-router-dom'


function V_KelolaRule({ v_kelolarule }) {


  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='../js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {
  return (

    <tr>
      <td>{v_kelolarule.id}</td>
      <td>{v_kelolarule.user_name}</td>
      <td>{v_kelolarule.user_create}</td>
      <td>{v_kelolarule.user_update}</td>

      <td>{v_kelolarule.user_name}</td>
      <td><Link to="/administrator/beranda" className="btn btn-primary">Edit</Link> &nbsp;
          <Link to="/" className="btn btn-danger">Delete</Link></td>
    </tr>
  )
}

export default V_KelolaRule