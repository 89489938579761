import React, { Component } from 'react'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditAplikasiMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      listugrup: [],
      nama_grub: '',
      level_name: '',
      level_id: '',
      filedatas: '',

    }
  }

  async ugrup() {
    await API.get("level/tampil/ntab/v?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
      .then(Response => this.setState({
        listugrup: Response.data
      }))
    // console.log(this.state.listugrup)
  }
  async editugrup() {
    const idapk = this.props.match.params.grub_id
    const res = await API.get("user/grub/tiap/id/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&grub_id=" + idapk)
    this.setState({
      nama_grub: res.data.nama_grub,
      level_name: res.data.level_name,
    })
    // console.log(res)
  }

  componentDidMount() {
    this.ugrup();
    this.editugrup();
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }
  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const idapk = this.props.match.params.grub_id
    await API.post('user/grub/lz/update/up' + key.baseURL + '&grub_id=' + idapk, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/usergroup')
    } else {
      alert("Gagal Update Aplikasi")
      window.location.reload()
    }

  }

  render() {

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Group Menu System</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-6 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Group Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form method="POST" action="http://192.168.2.181/apinew/public/menu/sub/astor/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group-row">
                        {/* start form group */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Nama User Group</label>
                            <div className="input-group">
                              <div className="input-group-addon"><i className="fa fa-user"></i>
                              </div>
                              <input name="nama_grub" value={this.state.nama_grub} className="form-control" required placeholder="Admin Sekretariat" onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Menu Sistem</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-users"></i>
                              </div>
                              <select name="level_id" className="form-control" placeholder="-- Pilih Menu Sistem --" required onChange={this.handleInputChange}>
                                <option defaultValue={this.state.level_id} selected >{this.state.level_name}</option>
                                {
                                  this.state.listugrup.map(listugrup => {
                                    return <option value={listugrup.level_id}>{listugrup.level_name}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 text-right" style={{ marginTop: '10px' }}>
                        <a href="javascript:window.history.go(-1);" className="btn btn-danger">Kembali</a>
                        <button onSubmit={this.handleSubmit} className="btn btn-primary" style={{ marginLeft: '5px' }}>Simpan</button>&nbsp;
                        {/* <button type="submit" className="btn btn-primary" style={{ marginLeft: '10px', width: '70px' }}>Tambah</button>&nbsp; */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
