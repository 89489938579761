import React from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'

function V_Usergroup({ V_ugrup }) {

  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {
    await API.put('user/grb/hps/dipek/dl' + key.baseURL + '&id_urt=' + V_ugrup.id_urt)
    window.location.reload()
  }
  function pop() {
    var pop = window.confirm('Yakin ingin menghapus?')
    if (pop == true) {
      handleClick()
    } else {
      // window.location.reload()
    }
  }
  async function actf() {
    await API.put('aktf/usr/gb/ntab/dl' + key.baseURL + '&id_urt=' + V_ugrup.id_urt)
    window.location.reload()
  }

  const hhdn = (val) => {
    if (val === "1") return <td style={{ textAlign: 'center' }}>
      <Link to={'/administrator/kelolasubmenu/' + V_ugrup.id_urt} style={{ marginRight: '5px' }} className="btn btn-warning">Edit</Link>
      <button onClick={pop} className="btn btn-danger">Delete</button>
    </td>
    else return <td style={{ textAlign: 'center' }}>
      <button onClick={actf} className="btn btn-success">Aktifkan</button>
    </td>
  }
  const ckyes = (val) => {
    if (val == "1") return hhdn(V_ugrup.id_hidden)
    else return ''
  }
  return (
    <tr>
      <td>{V_ugrup.No}</td>
      <td>{V_ugrup.level_name}</td>
      <td>{V_ugrup.nama_grup}</td>
      <td>{V_ugrup.user_name}</td>
      {ckyes(V_ugrup.groub_hidden)}
    </tr>
  )
}

export default V_Usergroup;