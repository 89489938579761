import React from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'

function V_Usergroup({ V_uakses }) {

  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);
  return (
    <tr>
      <td>{V_uakses.No}</td>
      <td>{V_uakses.nama_grub}</td>
      <td style={{ textAlign: 'center' }}>
        <Link to={`/administrator/kelolasubmenu/${V_uakses.grub_id}`} className="btn btn-warning"> Setting</Link>
      </td>
    </tr>
  )
}

export default V_Usergroup;