import React, { Component } from 'react'
import { Route , withRouter} from 'react-router-dom';
import { browserHistory } from 'history'


export default class Header extends Component {

  constructor() {
    super()
    this.state = {
      uns: ''
    }
  }
  async componentDidMount(){
    this.setState({
      uns:sessionStorage.getItem("un",JSON.stringify(this.state.uns))
    })
    // this.timer = setInterval(() => this.cek(), 1000);

    // console.log(sessionStorage.getItem("un",JSON.stringify(this.state.uns)))
  }
  
  // cek(){
    
  //   if (this.state.uns = sessionStorage.getItem("un") == null) {
  //     // console.log("admin login")
  //     this.props.history.push('/')
  //     // window.location.reload('/');;
      
  //   } else {
  //     // console.log("user login")
  //     // this.props.history.push('/')
  //     // window.location.reload();
  //     // window.location.reload();;
  //   }
  //   console.log(this.state.uns)

  //   //console.log(sessionStorage.getItem("un",JSON.stringify(this.state.uns)))
  // }
  

  render() {
    return (
      <div className="wrapper">
        <header className="main-header">
          {/* Logo */}
          <a href="/" className="logo">
            {/* mini logo for sidebar mini 50x50 pixels */}
            <span className="logo-mini"><b>I</b>TB</span>
            {/* logo for regular state and mobile devices */}
            <span className="logo-lg"><b>SEKRETARIAT</b></span>
          </a>
          {/* Header Navbar: style can be found in header.less */}
          <nav className="navbar navbar-static-top">
            {/* Sidebar toggle button*/}
            <a href="fake_url" className="sidebar-toggle" data-toggle="push-menu" role="button">
              <span className="sr-only">Toggle navigation</span>
            </a>

          </nav>
        </header>
      </div>

    )
  }
}

