import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'


export default class F_KelolaRule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      level_id: ''

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('create_recieve.php', this.state)
    this.props.history.push('/distribusisurat')
    // const data = this.state
    // console.log("Data berhasil", data)
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
  }


  componentDidMount() {
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {
    return (
      <div>
        <div className="col-md-12 col-sm-12 margin-bottom-30">
          <div className="box box-primary box-solid">
            <div className="box-header">
              <strong>Kelola Rule</strong>
              <div className="box-tools pull-right">
              </div>
            </div>
            {/* /.box-header */}
            <div className="box-body">
              <form onSubmit={this.handleSubmit} name="" action="" method="" >


                <div className="form-group">
                  <div className="col-md-6">
                    <label>Level</label>
                    <div className="input-group">
                      <div className="input-group-addon">
                        <i className="fa fa-signal"></i>
                      </div>
                      <select name="" className="form-control">
                        <option></option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-md-6">
                    <label>Menu Sistem</label>
                    <div className="input-group">
                      <div className="input-group-addon">
                        <i className="fa fa-bars"></i>
                      </div>
                      <select name="" className="form-control">
                        <option></option>
                      </select>
                    </div>
                  </div>


                  <div className="form-group">
                    <div className="col-md-6">
                      <label>Sub Menu</label>
                      <div className="input-group">
                        <div className="input-group-addon">
                          <i className="fa fa-bars"></i>
                        </div>
                        <select name="" className="form-control">
                          <option></option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-6">
                      <label>Sub Sub Menu</label>
                      <div className="input-group">
                        <div className="input-group-addon">
                          <i className="fa fa-bars"></i>
                        </div>
                        <select name="" className="form-control">
                          <option></option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <br />   <br />   <br />   <br /> <br />   <br />   <br />   <br />
                  <button className="btn btn-primary">Tambah</button>

                </div>

              </form>

            </div>

            {/* /.box-body */}
          </div>

        </div>


      </div>
    )
  }
}
