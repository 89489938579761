import React, { Component } from 'react'
import V_LogBAU from './V_LogBAU'
import API from '../../axios/Api'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import F_LogBAU from './F_LogBAU'


export default class LogBAU extends Component {

  state = {
    logbau: []
  }

  async componentDidMount() {
    await API.get("read_logletter.php")
      .then(Response => this.setState({
        logbau: Response.data
      }))

    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {

    const renderData = this.state.logbau.map(logbau => {
      return (
        <V_LogBAU v_logbau={logbau} key={logbau.Log_id} />
      )
    })

    return (
      // <div className="wrapper">
      //   <div className="">
      //     <div className="wrapper">
      //       <Menu />
      //       <Header />
      //       <div className="content-wrapper">
      //         {/* Content Header (Page header) */}
      //         <section className="content-header">
      //           <h1>
      //             Log BAU
      //           </h1>
      //         </section>
      //         {/* Main content */}
      //         <section className="content">
      //           <div className="row">
      //             <div className="col-md-12 col-sm-12 margin-bottom-30">
      //               <div className="box box-success box-solid">
      //                 <div className="box-header">
      //                   <strong>Daftar Log BAU</strong>
      //                   <div className="box-tools pull-right">
      //                   </div>
      //                 </div>
      //                 {/* /.box-header */}
      //                 <div className="box-body">
      //                   <div className="table-responsive">
      //                     <table id="example1" className="table table-bordered table-hover">
      //                       <thead>
      //                         <tr>
      //                           <th>No</th>
      //                           <th>User Name</th>
      //                           <th>Date Login</th>
      //                           <th>Date Logout</th>
      //                           <th>Action</th>
      //                         </tr>
      //                       </thead>
      //                       <tbody>
      //                         {renderData}
      //                       </tbody>
      //                     </table>
      //                   </div>
      //                   {/* /.box-body */}
      //                 </div>
      //               </div>

      //             </div>
      //             {/* /.col */}

      //             <div className="col-md-12 col-sm-12 margin-bottom-30">
      //               <div className="box box-primary box-solid">
      //                 <div className="box-header">
      //                   <strong>Backup Date</strong>
      //                   <div className="box-tools pull-right">
      //                   </div>
      //                 </div>
      //                 {/* /.box-header */}
      //                 <div className="box-body">
      //                   <F_LogBAU />
      //                   {/* <form onSubmit={this.handleSubmit} name="" action="" method="" >

       
      //                <div className="form-group">
      //                <div className="col-md-6">
      //                 <label>Start</label> 
      //                <div className="input-group">
      //                  <div className="input-group-addon">
      //                   <i className="fa fa-calendar"></i>
      //                </div> 
      //                  <input type="date" name="" className="form-control" onChange={this.hndeleInputChange} placeholder="Sub Menu"></input>          
      //               </div>
      //                </div>
      //                </div>

      //                <div className="form-group">
      //                <div className="col-md-6">
      //                  <label>End</label> 
      //                <div className="input-group">
      //                  <div className="input-group-addon">
      //                   <i className="fa fa-calendar"></i>
      //                </div> 
      //                  <input type="date" name="" className="form-control" onChange={this.hndeleInputChange} placeholder="Sub Menu"></input>          
      //               </div>
      //               </div>

      //               <br/>   <br/>   <br/>   <br/>
      //               <button  className="btn btn-primary">Backup Log</button> &nbsp;
      //                <button  className="btn btn-success">Back</button>

      //                </div>
                
      //           </form> */}
      //                 </div>
      //                 {/* /.box-body */}
      //               </div>
      //             </div>
      //           </div>
      //           {/* /.row */}
      //         </section>
      //         {/* /.content */}
      //       </div>
      //       <Footer />
      //     </div>
      //   </div>
      // </div>
      <div></div>
    )
  }
}

