import React from 'react'
import API from '../../axios/Api'

function GET({ V_listmenu }) {
    
    return (
        <div className="box box-info box-solid">
            <div className="box-header">
                <strong>{V_listmenu.nama_aplikasi}</strong>
                <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                    </button>
                </div>
            </div>
            {/* /.box-header */}
            <div className="box-body">
                {Object.values(V_listmenu.menu).map(V_listmenus =>{

                    async function handleClick() {
                        const ads = this.props.match.params.id_urt
                        await API.put('aktf/menu/new/dl?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&menu_id=' + V_listmenus.menu_id + '&adsa=' + ads)
                        // alert(V_listmenus.menu_id)
                        window.location.reload();
                    }
                    return(
                        <ul className="list-group-item" key={V_listmenus.menu_id}>
                            <li className="list-group-item list-group-item-success"  style={{ paddingBottom: '15px', fontSize: '18px'}}>
                                {V_listmenus.grup}
                                <button onClick={handleClick} style={{ float: 'right', marginRight: '15.5px'}} className="btn btn-success">Tambah</button>
                            </li>
                            <li className="list-group-item">
                                {Object.values(V_listmenus.sub).map(V_listsub =>{
                                    async function handleClick2() {
                                        await API.put('aktf/menu/new/dl?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&menu_id=' + V_listsub.menu_id)
                                        // alert(V_listsub.menu_id)
                                        window.location.reload();
                                    }
                                    return(
                                        <li className="list-group-item list-group-item-warning" style={{ paddingBottom: '15px', fontSize: '18px'}} key={V_listsub.menu_id}>
                                            {V_listsub.menu_name}
                                            {/* <button onClick={handleClick2} style={{ float: 'right'}} className="btn btn-success">Tambah</button> */}
                                        </li>
                                    )
                                })}
                            </li>
                        </ul>
                    )
                })}
            </div>
        </div>
    )
}
export default GET