import React, { Component } from 'react'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class T_sistemmenu extends Component {



  constructor(props) {
    super(props)
    this.state = {
      level_name: '',
      filedatas: '',
    }
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
    document.getElementById("paket").style.cssText = "background-color: black;";
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('menu/sistem/administrator/add' + key.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/sistemmenu')
    } else {
      alert("Gagal Membuat Surat")
      window.location.reload()
    }

  }

  async componentDidMount() {
    await API.get('aplikasi/admin/wkwk/view' + key.baseURL)
      // await API.get('test' + key.baseURL)
      .then(Response => this.setState({
        objek: Response.data
      }))
    // console.log(this.state.level_list)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }





  render() {

    const scriptku = document.createElement("script");

    scriptku.src = '../js/multipleselect.js';
    scriptku.async = true;

    document.body.appendChild(scriptku);




    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Sistem Menu</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Tambah Sistem Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        {/* start form group */}
                        <div className="table-responsive">
                          <div className="col-md-10">
                            <div className="form-group">
                              <label>Nama Menu System</label>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-user"></i>
                                </div>
                                <input type="text" name="level_name" className="form-control" required onChange={this.handleInputChange} placeholder="Nama Menu System"></input>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Aplikasi</label>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-user"></i>
                                </div>
                                <select id="paket" className="form-control" multiple="multiple" data-placeholder="Select a State">
                                  <option value=""></option>
                                  <option value="Web Master">Web Master</option>
                                  <option value="Web Programming">Web Programming</option>
                                  <option value="Web Design">Web Design</option>
                                  <option value="Digital Marketing">Digital Marketing</option>
                                  <option value="Coding For Kids">Coding For Kids</option>
                                  <option value="Grafic Desain">Grafic Desain</option>
                                  <option value="Motion Grafic">Motion Grafic</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label>Inputan Kosong</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bars"></i>
                              </div>
                              <input name="hal_suratkeluar" value="" readOnly required className="form-control" onChange={this.handleInputChange} placeholder="" />
                            </div>
                          </div>
                        </div> */}


                        {/* end form group */}
                      </div>
                      <div className="col-md-12 text-right" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <a href="javascript:window.history.go(-1);" className="btn btn-warning">Kembali</a> &nbsp;
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div >
    )
  }
}
