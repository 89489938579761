import React, { Component } from 'react'



function V_LogBAA({ v_logbaa }) {


  const script = document.createElement("script");
  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='../js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {
  return (
    <tr>
      <td>{v_logbaa.Log_id}</td>
      <td>{v_logbaa.User_name}</td>
      <td>{v_logbaa.Log_date}</td>
      <td>{v_logbaa.Log_date}</td>
      <td>{v_logbaa.Log_action}</td>
    </tr>


  )
}
export default V_LogBAA
