import React, { Component } from 'react'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditAplikasiMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      objek: [],


    }
  }

  // async grpmenu() {
  //   await API.get("menu/grub/caca/lv/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
  //     .then(Response => this.setState({
  //       objek: Response.data
  //     }))
  //   // console.log(this.state.objek)
  // }
  // async grpmenu2(){
  //   const idapk = this.props.match.params.menu_id
  //   const res = await API.get('/sub/di/idu/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&menu_id=' + idapk)
  //   this.setState({
  //     aplikasi_id: res.data[0].aplikasi_id,
  //     link: res.data[0].link,
  //     menu_name: res.data[0].menu_name,
  //     sub_menu_id: res.data[0].sub_menu_id,
  //     gmenu: res.data[0].gmenu,
  //   })
  //   console.log(res)
  // }
  // componentDidMount() {
  //   this.grpmenu();
  //   this.grpmenu2();
  // }
  // handleInputChange = (event) => {
  //     event.preventDefault()
  //     // console.log(event)
  //     // console.log(event.target.name)
  //     console.log(event.target.value)
  //     this.setState({
  //       [event.target.name]: event.target.value
  //     })
  //   }

  // handleSubmit = async (event) => {
  //   event.preventDefault()
  //   const idapk = this.props.match.params.menu_id
  //   await API.post('sub/menu/lw/update/up' + key.baseURL + '&menu_id=' + idapk, this.state)
  //     .then(Response =>
  //       this.setState({
  //         filedatas: Response.data[0].filedatas
  //       })
  //     )

  //   if (this.state.filedatas == 1) {
  //     alert("Berhasil !!")
  //     window.location.replace('/administrator/submenu')
  //   } else {
  //     alert("Gagal Update Aplikasi")
  //     window.location.reload()
  //   }

  // }

  render() {

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Setting Akses</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-6 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Akses User</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form method="POST" action="http://192.168.2.181/apinew/public/menu/sub/astor/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group-row">
                        {/* start form group */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>User Group</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-users"></i>
                              </div>
                              <select name="level_id" className="form-control" placeholder="-- pilih user group --" required onChange={this.handleInputChange}>
                                <option value="" disabled selected hidden>-- Pilih User Group --</option>
                                {/* {
                                  this.state.listugrup.map(listugrup => {
                                    return <option value={listugrup.level_id}>{listugrup.level_name}</option>
                                  })
                                } */}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Link to="/administrator/kelolauser" className="btn btn-warning">Kembali</Link>&nbsp;
                        <button onSubmit={this.handleSubmit} className="btn btn-primary" style={{ width: '70px' }}>Simpan</button>
                        {/* <button type="submit" className="btn btn-primary" style={{ marginLeft: '10px', width: '70px' }}>Tambah</button>&nbsp; */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
