import React, { Component } from 'react'
import V_LogSurat from './V_LogSurat'
import API from '../../axios/Api'
import bissmillah from '../../axios/Api_surat'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import F_LogSurat from './F_LogSurat'
import key from '../../axios/key'
import ksurat from '../../axios/key_surat'

export default class LogSurat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logsurat: [],
      as: '0',
      data: []
    }

  }

  downloadsql = async () => {
    const res = await API.get('test/taricha/view' + key.baseURL)
      // alert('Berhasil Download');
      .then(res => {
        if (res.status === 200) {
          this.setState({
            data: res.data
          })
        }
        var data = this.state.data;
        if(data == '')
            return;
        JSONToCSVConvertor(data, true);
      })
    
    function JSONToCSVConvertor(JSONData,ShowLabel) {    
      var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;   
      var CSV = '';     
      if (ShowLabel) {
         var row = "";
         for (var index in arrData[0]) {
             row += index + ',';
         }
         row = row.slice(0, -1);
         CSV += row + '\r\n';
      }
      for (var i = 0; i < arrData.length; i++) {
         var row = "";
         for (var index in arrData[i]) {
            var arrValue = arrData[i][index] == null ? "" : '="' + arrData[i][index] + '"';
            row += arrValue + ',';
         }
         row.slice(0, row.length - 1);
         CSV += row + '\r\n';
      }
      if (CSV == '') {        
         alert("Invalid data");
         return;
      }   
      var fileName = "BackupLogSurat";
      if(msieversion()){
      var IEwindow = window.open();
      IEwindow.document.write('sep=,\r\n' + CSV);
      IEwindow.document.close();
      IEwindow.document.execCommand('SaveAs', true, fileName + ".csv");
      IEwindow.close();
      } else {
       var uri = 'data:application/csv;charset=utf-8,' + escape(CSV);
       var link = document.createElement("a");    
       link.href = uri;
       link.style = "visibility:hidden";
       link.download = fileName + ".csv";
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
      }
    }
    function msieversion() {
      var ua = window.navigator.userAgent; 
      var msie = ua.indexOf("MSIE "); 
      if (msie != -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number 
      {
        return true;
      } else { // If another browser, 
        return false;
      }
        return false; 
    }
    var pop = window.confirm("Hapus Data Secara Permanen?")
    if (pop == true){
      this.handleHapus();
    }else {
    }
      
    // const nomorTogel = [2, 3, 5, 6, 7, 8, 9, 10];
    // const nomorTogelTembus = nomorTogel.map(
    //   (nomor) => (nomor * 2)
    // )
    // console.log(this.state.data)
  }
  async handleHapus(){
    await API.delete('back/up/hapus/{dl}' + key.baseURL)
    window.location.reload();
  }

  async componentDidMount() {

    let ico = []
    const logsurat = await bissmillah.get('/surat/Log/Surat/view' + ksurat.baseURL)
    const eserdata = await API.get('/surat/Log/Surat/dicoba/json' + key.baseURL)
    for (const lognya of logsurat.data) {
      const ketemuuser = eserdata.data.find(val => val.id === lognya.id)
      ico.push({ ...lognya, ...ketemuuser })
    }
    this.state.logsurat = ico.map((val, idx) => ({ No: idx + 1, ...val }))
    // console.log(ico)

    let timerloading = setInterval(() => {
      if (this.state.as >= 1) {
        clearInterval(timerloading);
        var loadingmamak = document.querySelector(".loading");
        loadingmamak.classList.remove("loading");
      } else {
        this.setState({ as: this.state.as + 1 });
      }
    }, 100);

    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {

    const renderData = this.state.logsurat.map(logsurat => {
      return (
        <V_LogSurat v_logsurat={logsurat} key={logsurat.Log_id} />
      )
    })
    
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          <div className="loading"></div>
          <section className="content-header">
            <h1>Log Surat</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>List Log Surat</strong>
                    <div className="box-tools pull-right">
                      {/* <button className="btn btn-success" onClick={this.clicksemua}>Semua Surat</button> */}
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table id="example1" className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Username</th>
                            <th>Tanggal Log</th>
                            <th>Keterangan Aksi Log</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData
                            // this.state.logsurat.map( (v_logsurat, idx) =>  { 
                              
                            //   return (
                            //     <tr>
                            //       <td>{v_logsurat.No}</td>
                            //       <td>{v_logsurat.user_name}</td>
                            //       <td>{v_logsurat.Log_date}</td>
                            //       <td>{v_logsurat.Log_action}</td>
                            //     </tr>
                            //   )
                            // }
                            // )
                          }
                          {/* <V_LogSurat /> */}
                        </tbody>
                      </table>
                      {/* <button onClick={this.downloadEmployeeData} className="btn btn-primary">BACKUP LOG</button> */}
                      <Link onClick={this.downloadsql} className="btn btn-primary" >Download Log</Link>
                    </div>
                    {/* /.box-body */}
                  </div>
                </div>

              </div>
              {/* /.col */}

              {/* <div className="col-md-12 col-sm-12 margin-bottom-30">
                    <div className="box box-primary box-solid">
                      <div className="box-header">
                        <strong>Backup Log</strong>
                        <div className="box-tools pull-right">
                        </div>
                      </div>

                      <div className="box-body">
                        <form name="" action="" method="">
                          <div className="col-md-12 text-bold" style={{ marginBottom: '1%', marginTop: '3%' }}>
                            <label>Pilih tanggal backup Log :</label>
                          </div>

                          <div className="col-md-12" style={{ marginBottom: '5%' }}>
                            <div className="col-md-3">
                              <p>dari tanggal</p>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-calendar"></i>
                                </div>
                                <input type="date" name="" className="form-control" onChange={this.handleInputChange} placeholder="Sub Menu"></input>
                              </div>
                            </div>

                            <div className="col-md-1">
                              <br />
                              <img
                                style={{ width: '95%', opacity: '0.5', marginTop: '17px' }}
                                src="https://i.ibb.co/WGtF4vt/arrow.png" />
                            </div>

                            <div className="col-md-3">
                              <p>sampai tanggal</p>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-calendar"></i>
                                </div>
                                <input type="date" name="" className="form-control" onChange={this.handleInputChange} placeholder="Sub Menu"></input>
                              </div>
                            </div>

                            <div className="col-md-3" style={{ marginTop: '24px' }}>
                              <button className="btn btn-primary">BACKUP LOG</button>
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>

                  </div> */}

            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}

