import React, { Component } from 'react'

import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'



export default class Beranda extends Component {

    componentDidMount() {
        this.timer = setInterval(() => this.sesscek(), 1000);
    }

    addCookie() {
        var num = 1;
        document.cookie = num + " = " + num;
        num++;
    }

    removeCookies() {
        localStorage.clear()
    }

    sesscek() {
        if (sessionStorage.getItem("adm") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        } else if (localStorage.getItem("logus") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        }

    }
    render() {

        return (
            <div>
                <Menu />
                <Header />
                <div className="content-wrapper">
                    <section className="content">
                        <h1>Selamat datang</h1>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}
