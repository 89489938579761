import React from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'
import API_ASTOR from '../../axios/Api_surat'
import key_surat from '../../axios/key_surat'


function V_StrukturalJabatan({ V_strukjb }) {

    const script = document.createElement("script");

    script.src = '../js/content.js';
    script.async = true;

    document.body.appendChild(script);

    async function handleClick() {
        await API.put('nnaktf/strkrl/jbtn/dl' + key.baseURL + '&id_rektor=' + V_strukjb.id_rektor)
        await API_ASTOR.put('nnaktf/strkrl/jbtn/dl' + key_surat.baseURL + '&id_rektor=' + V_strukjb.id_rektor)
        window.location.reload()
      }
      function pop() {
        var pop = window.confirm('Yakin ingin menghapus?')
        if (pop == true) {
          handleClick()
        } else {
          // window.location.reload()
        }
      }
      async function actf() {
        await API.put('aktfi/strk/jbt/dl' + key.baseURL + '&id_rektor=' + V_strukjb.id_rektor)
        await API_ASTOR.put('aktfi/strk/jbt/dl' + key_surat.baseURL + '&id_rektor=' + V_strukjb.id_rektor)
        window.location.reload()
      }

      const hhdn = (val) => {
        if (val === "1") return <td style={{ textAlign: 'center' }}>
          <Link to={'/administrator/editStruktural/' + V_strukjb.id_rektor} className="btn btn-info">Edit</Link> &nbsp;
          <button onClick={pop} className="btn btn-danger">Delete</button>
        </td>
        else return <td style={{ textAlign: 'center' }}>
          <button onClick={actf} className="btn btn-info">Aktifkan</button>
        </td>
      }
      const ckyes = (val) => {
        if (val == "1") return hhdn(V_strukjb.id_hidden)
        else return ''
      }

    return (
        <tr>
      
         <td>{V_strukjb.No}</td>
         <td>{V_strukjb.nidn}</td>
         <td>{V_strukjb.user_name}</td>
         {/* <td>{V_strukjb.user_name}</td> */}
         <td>{V_strukjb.rektor_id}</td>
         <td>{V_strukjb.ketkode_rektor}</td>
         <td>{V_strukjb.level_name}</td>
         {/* <td>{V_strukjb.nidn_rektor}</td> */}
         <td>{V_strukjb.p1_rekto}</td>
         <td>{V_strukjb.p2_rekto}</td>
         {ckyes(V_strukjb.grub_hidden)}
        </tr>
    )
}

export default V_StrukturalJabatan;