import React, { Component } from 'react'
import V_LogBAA from './V_LogBAA'
import API from '../../axios/Api'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import V_KelolaRule from './V_KelolaRule'
import F_KelolaRule from './F_KelolaRule'

export default class KelolaRule extends Component {

  state = {
    kelolarule: []
  }

  async componentDidMount() {
    await API.get("read_userentity.php")
      .then(Response => this.setState({
        kelolarule: Response.data
      }))

    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {

    const renderData = this.state.kelolarule.map(kelolarule => {
      return (
        <V_KelolaRule v_kelolarule={kelolarule} key={kelolarule.Log_id} />
      )
    })

    return (
      <div>
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">

          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <F_KelolaRule />
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>Kelola Rule</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <table id="example1" className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Level</th>
                            <th>Menu</th>
                            <th>Sub Menu</th>
                            <th>Sub Sub Menu</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData}
                        </tbody>
                      </table>
                    </div>
                    {/* /.box-body */}
                  </div>
                </div>

              </div>
              {/* /.col */}


            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}

