import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class T_groupmenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      objek: [],
      menu_name: '',
      filedatas: '',
      aplikasi_id: '',
    }
  }
  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('menu/grub/administrator/add' + key.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/sistemmenu/group')
      // history.pushState(this.state.table2, '', '/administrator/sistemmenu')
    } else {
      alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
      // history.pushState(this.state.table2, '', '/administrator/sistemmenu')
    }

  }
  //   async componentDidMount() {
  //     await API.get('/administrator/level/adit/akses/all/v' + key.baseURL)
  //       .then(Response => this.setState({
  //         level_list: Response.data
  //       }))

  //       // console.log(this.state.level_list)
  //   }

  async componentDidMount() {
    await API.get('aplikasi/admin/wkwk/view' + key.baseURL + '&idacc=')
      // await API.get('test' + key.baseURL)
      .then(Response => this.setState({
        objek: Response.data
      }))
    // console.log(this.state.objek)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }


  render() {
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Group Menu System</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Tambah Group Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      {/* <form method="POST" action="http://192.168.2.181/apinew/public/menu/grub/administrator/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}

                      <div className="form-group-row">
                        {/* start form group */}

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Group Menu</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-group"></i>
                              </div>
                              <input type="text" name="menu_name" required className="form-control" onChange={this.handleInputChange} placeholder="Group Menu" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Aplikasi</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-window-maximize"></i>
                              </div>
                              <select name="aplikasi_id" className="form-control" required onChange={this.handleInputChange}>
                                <option value="" disabled selected hidden>-- Pilih Aplikasi --</option>
                                {
                                  this.state.objek.map(objek => {
                                    return <option value={objek.aplikasi_id}>{objek.nama_aplikasi}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>


                        {/* end form group */}
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <Link to="/administrator/sistemmenu/group" className="btn btn-danger" style={{ marginRight: '5px' }}>Kembali</Link>
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button> &nbsp;
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
