import React, { Component } from 'react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import API_SURAT from '../../axios/Api_surat'
import key from '../../axios/key'
import key_surat from '../../axios/key_surat'
import koneksi from '../../axios/koneksi';
// import koneksi_astor from '../../axios/koneksi_astor';
import koneksi_surat from '../../axios/koneksi_surat'

class T_Struktural extends Component {
    constructor() {
        super()
        this.state = {
            struktural: [],
            usrentiti: [],
            level: [],
            filedatas: '',
            nidn_rektor: '',
            rektor_name: '',
            rektor_id: '',
            ketkode_rektor: '',
            p1_rekto: '',
            p2_rekto: '',
            id: '',
            level_id: '',
            level_name: '',

            filedatas2: '',
            rektorid: '1',
            level_id: '',
            idnya: '',
            sid: '',
            idrektor: '2',
            as: '',
            levelid: '',

            data1: '',
            data2: '',
            data3: '',
            data4: '',
            data5: '',
            data6: '',
            data7: '',
            data8: '',
            data9: '',
            data10: '',
        }
    }
    async usrentiti() {
        await API.get('dropdown/usrentiti/combo/{v}' + key.baseURL)
            .then(Response => this.setState({
                usrentiti: Response.data
            }))
        //   console.log(this.state.usrentiti)
    }
    async levelusr() {
        await API.get('dropdon/strktr/comb/{v}' + key.baseURL)
            .then(Response => this.setState({
                level: Response.data
            }))
        //   console.log(this.state.level)
    }
    sesscek() {
        if (sessionStorage.getItem("adm") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        } else if (localStorage.getItem("logus") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        }
    }

    componentWillUnmount() {
        this.timer = null;
    }

    handleInputChange = (event) => {
        event.preventDefault()
        // console.log(event)
        // console.log(event.target.name)
        // console.log(event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    cc = (event) => {
        event.preventDefault()
        // console.log(event)
        // console.log(event.target.name)
        // console.log(event.target.value)
        this.setState({
            rektor_id: event.target.value
        })

        if (event.target.value == 'R.0') return this.setState({ ketkode_rektor: "Rektor" })
        else if (event.target.value == 'R.1') return this.setState({ ketkode_rektor: "Wakil Rektor I" })
        else if (event.target.value == 'R.2') return this.setState({ ketkode_rektor: "Wakil Rektor II" })
        else if (event.target.value == 'R.3') return this.setState({ ketkode_rektor: "Wakil Rektor III" })
        else if (event.target.value == 'R.4') return this.setState({ ketkode_rektor: "Wakil Rektor IV" })
        else if (event.target.value == 'LPMI') return this.setState({ ketkode_rektor: "Lembaga Penjaminan Mutu Internal" })
        else if (event.target.value == 'LP2M') return this.setState({ ketkode_rektor: "Lembaga Pendidikan dan Pengabdian Masyarakat" })
        else if (event.target.value == 'LPKD') return this.setState({ ketkode_rektor: "Lembaga Pengembangan Karier Dosen" })
        else if (event.target.value == 'KUI') return this.setState({ ketkode_rektor: "Kantor Urusan Internasional" })
        else if (event.target.value == 'USP') return this.setState({ ketkode_rektor: "Unit Sertifikasi Profesi" })
        else if (event.target.value == 'INBIS') return this.setState({ ketkode_rektor: "Inkubasi Bisnis" })
        else if (event.target.value == 'FEB') return this.setState({ ketkode_rektor: "Fakultas Ekonomi & Bisnis" })
        else if (event.target.value == 'FTD') return this.setState({ ketkode_rektor: "Fakultas Teknologi & Desain" })
        else if (event.target.value == 'S2-MM') return this.setState({ ketkode_rektor: "S2 Magister Manajemen" })
        // Fakultas FTD & FTD baru 2021
        else if (event.target.value == 'KP-INF') return this.setState({ ketkode_rektor: "Ketua PRODI TI" })
        else if (event.target.value == 'KP-DKV') return this.setState({ ketkode_rektor: "Ketua PRODI DKV" })
        else if (event.target.value == 'KP-SK') return this.setState({ ketkode_rektor: "Ketua PRODI SK" })
        else if (event.target.value == 'KP-AK') return this.setState({ ketkode_rektor: "Kaprodi Akuntansi" })
        else if (event.target.value == 'KP-PMB') return this.setState({ ketkode_rektor: "Kaprodi Profesional Bisnis Manajemen" })
        else if (event.target.value == 'SENAT') return this.setState({ ketkode_rektor: "Ketua Senat" })
        else if (event.target.value == 'SENAT') return this.setState({ ketkode_rektor: "Sekretaris Ketua Senat" })
        else if (event.target.value == 'HRD') return this.setState({ ketkode_rektor: "Kepala HRD" })
        else if (event.target.value == 'Fasilitas') return this.setState({ ketkode_rektor: "Kepala Sarpras" })
        else return ""
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        await API.post('jbtn/strktrl/add' + key.baseURL, this.state)
            .then(Response =>
                this.setState({
                    filedatas: Response.data[0].filedatas,
                    rektorid: Response.data[0].rektor_id,
                    levelid: Response.data[0].level_id,
                    nidn: Response.data[0].nidn,
                    user_name: Response.data[0].user_name,
                    idnya: Response.data[0].idnya,
                    sid: Response.data[0].sid,
                    idrektor: Response.data[0].id_rektor

                })
            )

        if (this.state.filedatas == 1) {

            let timerloading = setInterval(() => {

                if (this.state.as >= 1) {
                    clearInterval(timerloading);
                    //   var loadingmamak = document.querySelector(".loading");
                    //   loadingmamak.classList.remove("loading");
                    this.suratsync()
                } else {
                    this.setState({ as: this.state.as + 1 });
                }
            }, 1000);

        } else {
            alert("Ada data sudah terdaftar, Mohon Periksa Kembali !")
        }
    }

    async componentDidMount() {
        
        

        // console.log(this.state)

        this.usrentiti();
        this.levelusr();
        this.timer = setInterval(() => this.sesscek(), 1000);


    }

    async suratsync() {
        // console.log(this.state.rektorid, "====", this.state.idrektor)

        const asdd = await API.get('cari/struktural/terakhir/json' + key.baseURL)
        this.setState({
            data1: asdd.data[0].id,
            data2: asdd.data[0].id_rektor,
            data3: asdd.data[0].rektor_id,
            data4: asdd.data[0].ketkode_rektor,
            data5: asdd.data[0].rektor_name,
            data6: asdd.data[0].nidn_rektor,
            data7: asdd.data[0].level_id,
            data8: asdd.data[0].p1_rekto,
            data9: asdd.data[0].p2_rekto,
            data10: asdd.data[0].id_hidden,
        })

        await API_SURAT.post('jbtn/strktrl/surat/sinc/add' + key_surat.baseURL + '&rektorid=' + this.state.rektorid + '&idrektor=' + this.state.idrektor, this.state)
            .then(rss =>
                this.setState({
                    filedatas2: rss.data[0].filedatas
                })
            )
 

        if (this.state.filedatas2 == 1) {
            alert("Berhasil !!")
            window.location.replace('/administrator/strukturaljabatan')
        } else {
            await API_SURAT.post('jbtn/strktrl/surat/sinc/add' + key_surat.baseURL + '&rektorid=' + this.state.rektorid + '&idrektor=' + this.state.idrektor, this.state)
                .then(Response =>
                    this.setState({
                        filedatas2: Response.data[0].filedatas
                    })
                )
            alert("Berhasil !!")
            window.location.replace('/administrator/strukturaljabatan')
        }


    }


    render() {

        // const ketkode = (val) => {
        //     if (val == 'R.0') return this.state.rektor_name = "Rektor"
        //     else if (val == 'R.1') return this.state.rektor_name = "Wakil Rektor I"
        //     else if (val == 'LPMI') return this.state.rektor_name = "Lembaga P.. M.. I.."
        //     else return ""
        // }

        const ssss = (val) => {
            if (this.state.rektor_id == '') return ''
            // else return ketkode(this.state.rektor_id)
        }
        return (
            <div>
                <Menu />
                <Header />
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <h1>Tambah Struktural</h1>
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 margin-bottom-30">
                                <div className="box box-primary box-solid">
                                    <div className="box-header">
                                        <strong>Form Tambah Struktural</strong>
                                        <div className="box-tools pull-right">
                                        </div>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="box-body">
                                        <form onSubmit={this.handleSubmit}>
                                            {/* <form method="post" action={koneksi_surat.baseURL + 'jbtn/strktrl/surat/sinc/add' + key_surat.baseURL}> */}
                                            {/* <form method="post" action={koneksi.baseURL + 'jbtn/strktrl/add' + key.baseURL}> */}
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>NIDN</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <input name="nidn_rektor" className="form-control" required placeholder="NIDN" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>NAMA LENGKAP</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <input name="rektor_name" className="form-control" required placeholder="Nama Lengkap" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>User / Pengguna</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <select name="id" className="form-control" placeholder="-- pilih jabatan --" required onChange={this.handleInputChange}>
                                                            <option value="">-- pilih jabatan --</option>
                                                            {/* <option value="" disabled selected hidden>-- User / Pengguna --</option> */}
                                                            {this.state.usrentiti.map((data, idx) => {
                                                                return (
                                                                    <option value={data.id} key={idx}>{data.user_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Level Menu</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <select name="level_id" className="form-control" placeholder="-- pilih jabatan --" required onChange={this.handleInputChange}>
                                                            <option value="">-- Level Menu --</option>
                                                            {this.state.level.map((datas, idx) => {
                                                                return (
                                                                    <option value={datas.level_id} key={idx}>{datas.level_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>MASA JABATAN MULAI</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input type="date" name="p1_rekto" className="form-control" required placeholder="Masa Jabatan Mulai" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>JABATAN</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-lock"></i>
                                                        </div>
                                                        <select name="rektor_id" className="form-control" placeholder="-- pilih jabatan --" required onChange={this.cc}>
                                                            <option value="">-- pilih jabatan --</option>
                                                            <option value="R.0">[R.0] Rektor</option>
                                                            <option value="R.1">[R.1] Wakil Rektor I</option>
                                                            <option value="R.2">[R.2] Wakil Rektor II</option>
                                                            <option value="R.3">[R.3] Wakil Rektor III</option>
                                                            <option value="R.4">[R.4] Wakil Rektor IV</option>
                                                            <option value="LPMI">[LPMI] Lembaga Penjaminan Mutu Internal</option>
                                                            <option value="LP2M">[LP2M] Lembaga Pendidikan dan Pengabdian Masyarakat</option>
                                                            <option value="LPKD">[LPKD] Lembaga Pengembangan Karier Dosen</option>
                                                            <option value="KUI">[KUI] Kantor Urusan Internasional</option>
                                                            <option value="USP">[USP] Unit Sertifikasi Profesi</option>
                                                            <option value="INBIS">[INBIS] Inkubasi Bisnis</option>
                                                            <option value="FEB">[FEB] Fakultas Ekonomi & Bisnis</option>
                                                            <option value="FTD">[FTD] Fakultas Teknologi & Desain</option>
                                                            <option value="S2-MM">[S2-MM] S2 Magister Manajemen</option>
                                                            {/* Fakultas FTD & FTD baru 2021-2 */}
                                                            <option value="KP-INF">Ketua PRODI TI</option>
                                                            <option value="KP-DKV">Ketua PRODI DKV</option>
                                                            <option value="KP-SK">Ketua PRODI SK</option>
                                                            <option value="KP-AK">Kaprodi Akuntansi</option>
                                                            <option value="KP-PMB">Kaprodi Profesional Bisnis Manajemen</option>
                                                            <option value="SENAT">Ketua Senat</option>
                                                            <option value="SENAT">Sekretaris Ketua Senat</option>
                                                            <option value="HRD">HRD</option>
                                                            <option value="Fasilitas">Fasilitas</option>

                                                        </select>
                                                    </div>
                                                    {/* <div>{ssss(this.state.rektor_id)}</div> */}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>MASA JABATAN AKHIR</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input type="date" name="p2_rekto" className="form-control" required placeholder="Masa Jabatan Akhir" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Keterangan Jabatan</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input type="text" name="ketkode_rektor" className="form-control" required placeholder="Keterangan Jabatan" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 text-right" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <Link to="/administrator/strukturaljabatan" className="btn btn-warning">Kembali</Link> &nbsp;
                                                <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </section>
                    {/* /.content */}
                </div>
                <Footer />
            </div>
        );
    }
}

export default T_Struktural;