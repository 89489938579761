import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditSistemMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      level_name: '',
      filedatas: '',
      akses: [],
    //   level_id: '',
    }
  }

  // handleInputChange = (event) => {
  //   event.preventDefault()
  //   // console.log(event)
  //   // console.log(event.target.name)
  //   // console.log(event.target.value)
  //   this.setState({
  //     [event.target.name]: event.target.value
  //   })
  // }

  // handleSubmit = async (event) => {
  //   event.preventDefault()
  //   await API.post('menu/sistem/administrator/add' + key.baseURL, this.state)
  //     .then(Response =>
  //       this.setState({
  //         filedatas: Response.data[0].filedatas
  //       })
  //     )

  //   if (this.state.filedatas == 1) {
  //     alert("Berhasil !!")
  //     window.location.replace('/administrator/sistemmenu')
  //   } else {
  //     alert("Gagal Membuat Surat")
  //     window.location.reload()
  //   }

  // }

  async GrupAkses() {
    const akses_id = this.props.match.params.id
    await API.get("kll/peng/huha/view" + key.baseURL + '&id=' + akses_id)
      .then(Response => this.setState({
        akses: Response.data
      }))
    // console.log(this.state.akses)
  }

  async componentDidMount() {

    this.GrupAkses()
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  // async tambah() {
  //   const level_id = this.props.match.params.level_id
  //   await API.get("aktifkan/aps/menusis/add" + key.baseURL + '&level_id=' + level_id)
  //     .then(Response => this.setState({
  //       dataplikasi: Response.data
  //     }))
  // }

  simpanbos() {
    alert('Berhasil Simpan !!')
    window.location.href = "/administrator/kelolauser"
  }

  render() {

    const renderData = this.state.akses.map(akses => {
        async function tambah() {
            // const level_id = this.props.match.params.level_id
            await API.put("tmbh/kll/pgn/add" + key.baseURL + '&id=' + akses.id + '&grub_id=' + akses.grub_id )
            window.location.reload()
          }
        
          async function apus() {
            // const level_id = match.params.level_id
            await API.delete("del/peng/kel/dl" + key.baseURL + '&id_urt=' + akses.id_urt)
            window.location.reload()
          }
        
          const buttons = (val) => {
            if (val == null) return <button onClick={tambah} className="btn btn-success">Tambah Grup</button>
            else return <button onClick={apus} className="btn btn-danger">Hapus Grup</button>
          }

            return (
            <tr>
                <th>{akses.nama_grup}</th>
                <th style={{ textAlign: 'center' }}>
                {buttons(akses.id_urt)}
                </th>
            </tr>
            )
    })

    return (
      <div>
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              Kelola Grup Akses
        </h1>
          </section>
          {/* Main content */}
          <section className="content" style={{ paddingRight: '30px' }}>
            <div className="row">
              <div className="col-md-6 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Kelola Grup Akses</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="col-md-12">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="col-md-8 thku" style={{ textAlign: 'center' }} scope="col">Grup Akses</th>
                            <th className="col-md-5 thku" scope="col" style={{ textAlign: 'center' }}>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderData}
                          {/* {
                          this.state.dataplikasi.map(dataplikasi => {
                            return (
                              <tr>
                                <th>{dataplikasi.nama_aplikasi}</th>
                                <th style={{ textAlign: 'center' }}>
                                  {buttons(dataplikasi.btn)}
                                </th>
                              </tr>
                            )
                          })
                        } */}
                        </tbody>
                      </table>
                      <div className="row" style={{ marginBottom: '10px' }}>
                        <div className="col-md-6">
                          <div>
                            <Link to="/administrator/kelolauser" className="btn btn-warning">Kembali</Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-right">
                            <button onClick={this.simpanbos} className="btn btn-primary">Simpan</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.box-body */}
                </div>
              </div>
            </div>
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
