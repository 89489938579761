import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import V_SubMenu from './V_KelolaSubMenu'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import keyastor from '../../axios/key'

// https://id.reactjs.org/docs/thinking-in-react.html

export default class KelolaMenuSystem extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Recieve_code: '',
      Recieve_number: '',
      objek: [],
      aktif : [],

    }
  }


  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('', this.state)
    this.props.history.push('/kelolasubmenu')
    // const data = this.state
    // console.log("Data berhasil", data)
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value

    })
  }

  simpanbos() {
    alert('Berhasil Simpan !!');
    window.location.href="/administrator/usergroup"
  }



  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  script() {

    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);
  }
  async listactive() {
    const urt = this.props.match.params.id_urt
    const az = await API.get("ayeye/v" + keyastor.baseURL + '&urt=' + urt)
      this.setState({
        aktif: az.data
      })
      console.log(this.state.aktif)
      // console.log(this.state.aktif)
      // console.log(Object.values(this.state.aktif[0].menu))
  }

  async listmenu() {
    const idgrub = this.props.match.params.id_urt
    const az = await API.get("wah/weh/woh/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&id_urt=" + idgrub)
    const wow = await API.get("coba/tampil/menu/kanan/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
    
    var kanan = wow.data
    var kiri = az.data
    for( var i = 0; i < kanan.length; i++){
        var z = kiri.find(app => app.nama_aplikasi === kanan[i].nama_aplikasi)
        // console.log(z)
        if(z){
            for(var j = 0; j < z.menu.length; j++){
              var a1 = kanan[i].menu.findIndex(menu => menu.menu_id === z.menu[j].menu_id)
                if(a1 >= 0){
                  kanan[i].menu.splice(a1, 1)
                }
            }
        }
    }
    // console.log(kiri, kanan)
    // console.log(az.data ,wow.data)
    this.setState({
      objek: kanan,
    })
    // console.log(this.state.objek)
  }
  componentDidMount() {
    this.script();
    this.listactive();
    this.listmenu();
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  render() {
    // console.log(this.state.objek)
    const renderData = this.state.objek.map(objek => {
      return (
        <V_SubMenu V_listmenu={objek} key={objek.aplikasi_id} />
      )
    })
    const renderData2 = this.state.aktif.map(aktif => {
      
      async function handleClick() {
        await API.put('apus/menu/kiri/dl?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&id_rule2=' + aktif.id_rule2)
        // alert(subs.menu_id)
          window.location.reload();
      }
      return (
          <div className="box box-info box-solid" key={aktif.nama_aplikasi}>
            <div className="box-header">
              <strong>{aktif.nama_aplikasi}</strong>
                <div className="box-tools pull-right">
                    <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
                </div>
            </div>
            <div className="box-body">
              {Object.values(aktif.rule2).map(menus =>{
                return(
                  <ul class="list-group-item" key={menus.id_rule2}>
                    <li class="list-group-item list-group-item-success"  style={{ paddingBottom: '15px', fontSize: '18px'}}>{menus.menu_name}
                      <button onClick={handleClick} style={{ float: 'right', marginRight: '15.5px'}} className="btn btn-danger">Hapus</button>
                    </li>
                    <li class="list-group-item">
                    {Object.values(menus.sub_menu).map(subs =>{
                        async function handleClick2() {
                            await API.put('aktf/menu/new/dl?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&menu_id=' + subs.id_rule2)
                            // alert(V_listsub.menu_id)
                              window.location.reload();
                        }
                        return(
                            <li class="list-group-item list-group-item-warning" style={{ paddingBottom: '15px', fontSize: '18px'}} key={subs.menu_id}>
                            {subs.menu_name}  
                              <button onClick={handleClick2} style={{ float: 'right'}} className="btn btn-danger">Hapus</button>
                            </li>

                        )})}
                    </li>
                  </ul>
                )})}
            </div>  
        </div>
      )})

    return (
      <div className="wrapper">
        <Menu />
        <Header />

        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              Rule User Group
           </h1>
          </section>
          {/* Main content */}
          <section className="content" style={{ paddingRight: '30px' }}>
            <div className="row">
              <div className="col-md-6 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Kelola Rule Akses</strong>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="col-md-12">
                      {renderData2}
                      <div className="row" style={{ marginBottom: '10px' }}>
                        <div className="col-md-6">
                          <div>
                            <Link to="/administrator/usergroup" className="btn btn-warning">Kembali</Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-right">
                            <button onClick={this.simpanbos} className="btn btn-success">Simpan</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.box-body */}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 margin-bottom-30 mb-sm-0">
                  <div className="box box-success box-solid">
                    <div className="box-header">
                      <strong>List Menu</strong>
                    </div>
                    {/* /.box-header */}
                    <div className="box-body">
                    {renderData}
                    </div>
                    {/* /.box-body */}
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
       <Footer />
      </div>

    )
  }
}
