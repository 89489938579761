import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditAplikasiMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      objek: [],
      menu_name: '',
      groupmenu: '',
      // idapp: '',
      nameapp: '',
      aplikasi_id: '',

    }
  }

  async grpmenu() {
    const idacc = this.props.match.params.menu_id
    await API.get("aplikasi/admin/wkwk/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&idacc=" + idacc)
      .then(Response => this.setState({
        objek: Response.data
      }))
    // console.log(this.state.objek)
  }
  async grpmenu2() {
    const idapk = this.props.match.params.menu_id
    const res = await API.get('/grub/tiap/ids/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&menu_id=' + idapk)
    this.setState({
      nama_aplikasi: res.data.nama_aplikasi,
      aplikasi_id: res.data.aplikasi_id,
      menu_name: res.data.menu_name,
    })
    // console.log(this.state.aplikasi_id, this.state.menu_name)
  }
  componentDidMount() {
    this.grpmenu();
    this.grpmenu2();
  }
  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const idapk = this.props.match.params.menu_id
    await API.post('grub/menyu/lv/update/up' + key.baseURL + '&menu_id=' + idapk, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/sistemmenu/group')
    } else {
      alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
    }

  }

  render() {
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Group Menu System</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Group Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      {/* <form method="POST" action="http://192.168.2.181/apinew/public/menu/grub/administrator/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}

                      <div className="form-group-row">
                        {/* start form group */}

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Group Menu</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-bars"></i>
                              </div>
                              <input type="text" name="menu_name" defaultValue={this.state.menu_name} required className="form-control" onChange={this.handleInputChange} placeholder="Group Menu" />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Aplikasi</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-user"></i>
                              </div>
                              <select name="aplikasi_id" className="form-control" required onChange={this.handleInputChange}>
                                <option selected hidden defaultValue={this.state.aplikasi_id}>{this.state.nama_aplikasi}</option>
                                {
                                  this.state.objek.map(objek => {
                                    return <option value={objek.aplikasi_id}>{objek.nama_aplikasi}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>


                        {/* end form group */}
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Link to="/administrator/sistemmenu/group" className="btn btn-danger" style={{ marginRight: '5px' }}>Kembali</Link>
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button> &nbsp;
                        </div>
                    </form>
                  </div>
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
