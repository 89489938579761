import React, { Component } from 'react'
import Recaptcha from 'react-recaptcha'
import API from '../axios/Api'
import key from '../axios/key'
import config from '../axios/koneksi'


export default class Login extends Component {

  constructor() {
    super()
    this.state = {
      user_id: '',
      user_name: '',
      user_password: '',
      filedatas: '',
      idus: '',
      isVerified: true
      // login di local ganti ke true 
    }
    this.handleChange = this.handleChange.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.showPw = this.showPw.bind(this);
  }

  recaptchaLoaded() {
    // console.log('captcha successfully loaded');
  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true
      })
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  hedlelog = async (event) => {
    if (this.state.isVerified) {
      event.preventDefault()
      // await API.post('login/ul?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt', this.state)
      await API.post('login/ul' + key.baseURL, this.state)
        .then(Response =>
          this.setState({
            filedatas: Response.data[0].filedatas,
            idus: Response.data[0].idus
          })
        )
      this.ceklogin()
    } else {
      alert('silahkan isi captcha dahulu.. ya :D')
    }
  }

  ceklogin() {
    if (this.state.filedatas == 1) {
      const ar = [this.state]
      localStorage.setItem('ad', JSON.stringify(ar))
      this.props.history.push('/loading/' + this.state.idus)
    } else {
      alert("Gagal Login, Silahkan Login Kembali")
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem("un") == null) {
      sessionStorage.clear()
      localStorage.clear()
    } else {
      this.props.history.push('/loading/' + sessionStorage.getItem("un"))
    }
  }

  showPw() {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      document.getElementById("asem").style.color = "#32a2e3";
      // document.getElementById("asem").className.replace('fa fa-eye-slash')
    } else {
      x.type = "password";
      document.getElementById("asem").style.color = "#919191";
    }
  }

  render() {
    return (
      <div>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100 p-l-50 p-r-50 p-t-40 p-b-30">
              {/* <form id='demo-form' className="login100-form validate-form" action={`${config.baseURL}login/ul${key.baseURL}`} method="post"> */}
              <form onSubmit={this.hedlelog}>
                <h6 className="login100-form-title p-b-55" style={{ fontSize: '22px' }}>
                  <img width="31%" src="assets/images/asia.png" style={{ marginBottom: '7px' }}></img>
                  <br />
                ADMINISTRATOR
              </h6>
                <div className="wrap-input100 validate-input m-b-16" style={{ marginTop: '-20px' }}>
                  <input className="input100" type="text" name="user_name" onChange={this.handleChange} placeholder="Username" />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <span className="fa fa-user" />
                  </span>
                </div>
                <div className="wrap-input100 validate-input m-b-16">
                  <input className="input100" id="myInput" type="password" name="user_password" onChange={this.handleChange} placeholder="Password" />
                  <span className="focus-input100" />
                  <span className="symbol-input100">
                    <span className="fa fa-lock" />
                  </span>
                  <span onClick={this.showPw} id="asem" className="fa fa-eye" style={{position: 'absolute',
                  marginLeft: '90%', marginTop: '-11%', color: '#919191', fontSize: '16px'}}/>
                </div>
                <div className="wrap-input100 validate-input m-b-13">
                  <center>
                    <Recaptcha
                      sitekey="6LfBGSUfAAAAAEtbh5zrj6QPY41oZEwx5-clfu8_"
                      render="explicit"
                      onloadCallback={this.recaptchaLoaded}
                      verifyCallback={this.verifyCallback}
                    />
                  </center>
                </div>
                <div className="container-login100-form-btn p-t-25">
                  <button className="login100-form-btn" type="submit" data-callback='onSubmit'>
                    Sign In
                </button>
                  {/* onClick={this.valCapcay} */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
