import React, { Component } from 'react'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import V_Usergroup from './V_Usergroup'
import key from '../../axios/key'

export default class UserGroup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Recieve_code: '',
      Recieve_number: '',
      usergroup: [],


    }
  }

  componentDidMount() {
    this.grpmenu();
  }
  async grpmenu() {
    await API.get("user/grub/lw/view" + key.baseURL)
      .then(Response => this.setState({
        usergroup: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))
    // console.log(this.state.usergroup)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {

    const renderData = this.state.usergroup.map(usergroup => {
      return (
        <V_Usergroup V_ugrup={usergroup} key={usergroup.id_urt} />
      )
    })

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              User Grup
                </h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>User Grup System</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <div className="col-md-12">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Menu Sistem</th>
                              <th>User Grup</th>
                              <th>User Name</th>
                              <th>Opsi</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderData}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>No</th>
                              <th>Menu Sistem</th>
                              <th>User Grup</th>
                              <th>User Name</th>
                              <th>Opsi</th>
                            </tr>
                          </tfoot>
                        </table>
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                          <Link to={'T_usergroup'} className="btn btn-primary" style={{ marginLeft: '10px', marginTop: '20px', float: 'right' }}>Tambah </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
