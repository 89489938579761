import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditAplikasiMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filedatas: '',
      nama: '',
      versi: '',

    }
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const idapk = this.props.match.params.aplikasi_id
    await API.post('/administartor/aplikasi/update/up' + key.baseURL + '&aplikasi_id=' + idapk, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !")
      window.location.replace('/administrator/appmenu')
    } else {
      alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
    }

  }
  
  async componentDidMount() {
    const idapk = this.props.match.params.aplikasi_id
    const res = await API.get('aplikasi/per/id/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&aplikasi_id=' + idapk)
    this.setState({
      nama_aplikasi: res.data.nama_aplikasi,
      v_aplikasi: res.data.v_aplikasi,
    })
  }

  render() {
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Aplikasi Sistem Menu</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-6 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Edit Aplikasi Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group-row">
                        {/* start form group */}

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Nama Aplikasi</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-archive"></i>
                              </div>
                              <input type="text" name="nama_aplikasi" className="form-control" defaultValue={this.state.nama_aplikasi} onChange={this.handleInputChange} placeholder="Nama Aplikasi" required />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Versi Aplikasi</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-archive"></i>
                              </div>
                              <input type="text" name="v_aplikasi" className="form-control" defaultValue={this.state.v_aplikasi} onChange={this.handleInputChange} placeholder="Versi Aplikasi" required />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Link to="/administrator/appmenu" className="btn btn-warning">Kembali</Link> &nbsp;
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div >
    )
  }
}
