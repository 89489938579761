import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import conf from '../../axios/koneksi'
import key from '../../axios/key'

export default class TambahUser extends Component {

  constructor(props) {
    super(props)
    this.state = {
      user_id: '',
      user_name: '',
      user_password: '',
      user_pass_default: '',
      nidn: '',
      filedatas: '',
      pass: '',
      jabatan:'',
      alamat:'',
      no_hp:'',
      posisi1:'',
      posisi2:'',
      tgl_masuk: '',
      tgl_keluar: ''
    }
  }
  // handleSubmit = async (event) => {
  //   event.preventDefault()
  //   await API.post('create_recieve.php', this.state)
  //   this.props.history.push('/kelola/user/{add}')
  //   // const data = this.state
  //   // console.log("Data berhasil", data)
  // }
  async componentDidMount() {
    await API.get("/pass/deff/enkrp/v?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
      .then(Response => this.setState({
        pass: Response.data[0].pass
      }))
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  
  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('kelola/user/add' + key.baseURL, this.state
    // , {
    //   mode: 'no-cors',
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   }
    // }
    )
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )
        // console.log(this.state.filedatas)
    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/kelolauser')
    } else if (this.state.filedatas == 2) {
      alert("Lampiran harus mengandung angka 0 ateu lebih besar dari 0, selain itu tidak bisa")
    } else {
      alert("Ada data sudah terdaftar, Mohon Periksa Kembali !")
    }
  }

  render() {
    const script = document.createElement("script");

    script.src = '../js/formvalidation.js';
    script.async = true;

    document.body.appendChild(script);

    function validasiNIDN() {
      var nidn = document.getElementById('nidn').value;
      var panjangnidn = nidn.length;

      if (panjangnidn > 0 && panjangnidn < 8) {
        document.getElementById('3').innerHTML = 'Karakter Harus Berjumlah lebih dari 8';
        document.getElementById('31').innerHTML = '';
      } else if (panjangnidn < 1) {
        document.getElementById('3').innerHTML = 'NIDN Belum Diisi';
        document.getElementById('31').innerHTML = '';
      } else if (panjangnidn = null) {
        document.getElementById('3').innerHTML = '';
        document.getElementById('31').innerHTML = '';
      } else {
        document.getElementById('3').innerHTML = '';
        document.getElementById('31').innerHTML = 'NIDN sudah sesuai';
      }

    }
    function validasiPassword() {
      var password = document.getElementById('password').value;
      var passnya = password.length;

      if (passnya < 8) {
        document.getElementById('2').innerHTML = 'Password Minimal 8 Karakter';
        document.getElementById('21').innerHTML = '';
      }
      else if (passnya < 1) {
        document.getElementById('1').innerHTML = 'Password Belum Diisi';
        document.getElementById('11').innerHTML = '';
      }
      else if (passnya = null) {
        document.getElementById('2').innerHTML = '';
        document.getElementById('21').innerHTML = '';
      }
      else {
        document.getElementById('2').innerHTML = '';
        document.getElementById('21').innerHTML = 'Password sudah sesuai';
      }
    }
    function validasiUsername() {
      var userini = document.getElementById('username').value;
      if (userini.match(/^([a-zA-Z]+[a-zA-Z,.]+\s)*[a-zA-Z.,]+$/g)) {
        // if(userini.match(/^([a-zA-Z.,]+\s)*[a-zA-Z.,]+$/g)){
        document.getElementById('1').innerHTML = '';
        document.getElementById('11').innerHTML = 'Username sudah sesuai';
      }
      else if (userini < 1) {
        document.getElementById('1').innerHTML = 'Username Belum Diisi';
        document.getElementById('11').innerHTML = '';
      }
      else if (userini = null) {
        document.getElementById('1').innerHTML = '';
        document.getElementById('11').innerHTML = '';
      }
      else {
        document.getElementById('1').innerHTML = 'Username Hanya Boleh Menggunakan Huruf dan 1 Spasi';
        document.getElementById('11').innerHTML = '';
      }

    }

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Tambah User</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Form Tambah User</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      {/* <form method="POST" action="http://192.168.2.181/apinew/public/kelola/user/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>User ID</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-key"></i>
                            </div>
                            <input type="text" name="user_id" required className="form-control" onChange={this.handleInputChange} placeholder="User ID"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nama Pengguna</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-user"></i>
                            </div>
                            <input onKeyUp={validasiUsername} type="text" name="user_name" id="username" required className="form-control" onChange={this.handleInputChange} placeholder="User Name"></input>
                          </div>
                        </div>
                        <div id="1" style={{ color: 'red' }}></div>
                        <div id="11" style={{ color: 'green' }}></div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tanggal Masuk</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="date" id="tgl_masuk" required name="tgl_masuk" className="form-control" onChange={this.handleInputChange} placeholder="Tanggal Masuk"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tanggal Keluar</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="date" id="tgl_keluar" readOnly name="tgl_keluar" className="form-control" onChange={this.handleInputChange} placeholder="0000-00-00"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Password Default</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input defaultValue={this.state.pass} onKeyUp={validasiPassword} type="text" id="password" required name="user_pass_def" className="form-control" onChange={this.handleInputChange} placeholder="Password" readOnly></input>
                          </div>
                        </div>
                        <div id="2" style={{ color: 'red' }}></div>
                        <div id="21" style={{ color: 'green' }}></div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Alamat</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="text" id="alamat" required name="alamat" className="form-control" onChange={this.handleInputChange} placeholder="Alamat"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nomor HP</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="number" id="no_hp" required name="no_hp" className="form-control" onChange={this.handleInputChange} placeholder="Nomor HP"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>NIDN</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-clipboard"></i>
                            </div>
                            <input onKeyUp={validasiNIDN} id="nidn" onInput={(e) => { e.target.value = e.target.value.slice(0, 12) }} type="number" name="nidn" min="0" className="form-control" onChange={this.handleInputChange} placeholder="NIDN"></input>
                          </div>
                        </div>
                        <div id="3" style={{ color: 'red' }}></div>
                        <div id="31" style={{ color: 'green' }}></div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Posisi 1</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            {/* <input type="text" id="posisi1" required name="posisi1" className="form-control" onChange={this.handleInputChange} placeholder="Posisi 1"></input> */}
                            <select type="text" id="posisi1" required name="posisi1" className="form-control" onChange={this.handleInputChange} placeholder="Posisi 1" required>
                              <option value="">--Posisi 1--</option>
                              <option value="Dosen FTD">Dosen FTD</option>
                              <option value="Dosen FEB">Dosen FEB</option>
                              <option value="Karyawan">Karyawan</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Posisi 2</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="text" id="posisi2" required name="posisi2" className="form-control" onChange={this.handleInputChange} placeholder="Posisi 2"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Jabatan</label>
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-lock"></i>
                            </div>
                            <input type="text" id="jabatan" name="jabatan" className="form-control" onChange={this.handleInputChange} placeholder="Jabatan"></input>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Link to="/administrator/kelolauser" className="btn btn-warning">Kembali</Link> &nbsp;
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>
                      </div>
                    </form>
                  </div>
                  {/* /.box-body */}
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
