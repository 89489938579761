import React, { Component } from 'react'
import API from '../axios/Api'
import config from '../axios/koneksi'
import key from '../axios/key'
import V_Loading from './V_Loading'
import V_KelolaUser from './administrator/V_KelolaUser'

export default class Login extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      un: '',
      ua: '',
      usd: '',
      aks: '',
      user_nama: '',
      aksusers: ''
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('ad') == null) {
      sessionStorage.clear()
      localStorage.clear()
      window.location.replace('/loadingout')
    }

    const scp = this.props.match.params.un
    const res = await API.get('surat/search/lv/inview' + key.baseURL + '&uid=' + scp)
    this.setState({
      ua: res.data[0],
      un: res.data[0].usid,
      aks: res.data[0].aks,
      user_nama : res.data[0].user_nama
    })

    if (this.state.aks == 0) {
      window.location.replace('/loadingout')
    } else {
      sessionStorage.setItem("un", this.state.un)
      sessionStorage.setItem("adm", this.state.aks)
      sessionStorage.setItem("nama", this.state.user_nama)
      localStorage.setItem("logus", JSON.stringify(this.state.ua))
      // localStorage.setItem("lid", this.state.ua.lv[0].level_id)

      // if (localStorage.getItem('lid') == 0) {
      // console.log(localStorage.getItem('lid'))
      this.sesscek() 

      // UW1zdlNXZzVhbTByUjJJeFVIcFdaMnRZT1hGalJrOURTV3BuU2toSFZERkhiVVZUYTJORFpURTJVVDA9

      // console.log(res)0
      // } else {
      //   // window.location.replace('/loadingout')
      // }


      // console.log(res)

      // this.timer = setInterval(() => this.sesscek(), 1000);
    }


  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } 
    else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } 
    else {
      this.direk()
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  async direk() {
    // const sstate = await API.get('cari/id/sign/cari' + key.baseURL + '&id=' + user)
    const aksses = await API.get('cari/aks/sign/aks/vvv' + key.baseURL + '&ak=' + sessionStorage.getItem("adm"))
    this.setState({
      aksusers: aksses.data[0].level_id,
    })
    if (this.state.aksusers == 1) {
      localStorage.removeItem('ad')
      window.location.replace('/administrator/beranda')
    } else {
      sessionStorage.clear()
      localStorage.clear()
      window.location.replace('/loadingout')

    }
  }
  render() {

    return (
      <div>
        <div className="limiter">
          <div className="container-login100">
            <div className="col-md-3">
              <div className="box box-solid">
                <div className="overlay">
                  <li className="fa fa-refresh fa-spin" />
                </div>
              </div><br />
              <div className=" box-solid" align="center">
                <h4><b style={{ color: '#fff' }}>LOADING DATA .... {localStorage.getItem('lid')}</b></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
