import React, { Component } from 'react'


function V_akses({ v_akses }) {


  // const script = document.createElement("script");

  // script.src = '../js/content.js';
  // script.async = true;

  // document.body.appendChild(script);


  // componentDidMount (){
  //     const script = document.createElement("script");

  //     script.src ='../js/content.js';
  //     script.async = true;

  //     document.body.appendChild(script);
  // }

  // render() {
  return (
    <option value={v_akses.level_id}>{v_akses.level_name}</option>
  )
}

export default V_akses