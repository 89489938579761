import React, { Component } from 'react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class EditUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
          filedatas: '',
          user_id: '',
          user_name: '',
          user_pass_def: '',
          nidn: '',
          jabatan:'',
          alamat:'',
          no_hp:'',
          posisi1:'',
          posisi2:'',
          tgl_masuk:'',
          tgl_keluar: ''
    
        }
      }

      async componentDidMount() {
        const idus = this.props.match.params.id
        const res = await API.get('/usr/entiti/tiap/id/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt&id=' + idus)
        this.setState({
          user_id: res.data.user_id,
          user_name: res.data.user_name,
          tgl_masuk: res.data.tgl_masuk,
          tgl_keluar: res.data.tgl_keluar,
          user_pass_def: res.data.user_pass_def,
          nidn: res.data.nidn,
          jabatan: res.data.jabatan,
          alamat: res.data.alamat,
          no_hp: res.data.no_hp,
          posisi1: res.data.posisi1,
          posisi2: res.data.posisi2,
        })
      }

      handleInputChange = (event) => {
          event.preventDefault()
          // console.log(event)
          // console.log(event.target.name)
          // console.log(event.target.value)
          this.setState({
            [event.target.name]: event.target.value
          })
      }
      handleNIDN = (event) => {
        var nidn = document.getElementById('nidn');
        var panjangnidn = nidn.value.length;
        if(panjangnidn > 0 && panjangnidn < 8){
          nidn.setCustomValidity('Karakter Harus Berjumlah lebih dari 8');
          nidn.reportValidity();
        }
        else{
          nidn.setCustomValidity('');
          nidn.reportValidity();
          event.preventDefault()
          // console.log(event)
          // console.log(event.target.name)
          // console.log(event.target.value)
          this.setState({
            [event.target.name]: event.target.value
          })
        }
      }
      handlePass = (event) => {
        var password = document.getElementById('password');
        var passnya = password.value.length;
        if(passnya < 8){
          password.setCustomValidity('Karakter Harus Berjumlah lebih dari 8');
          password.reportValidity();
        }
        else{
          password.setCustomValidity('');
          password.reportValidity();
          event.preventDefault()
          // console.log(event)
          // console.log(event.target.name)
          // console.log(event.target.value)
          this.setState({
            [event.target.name]: event.target.value
          })
        }
      }
      handleUser = (event) => {
        var userini = document.getElementById('username');
        var userinis = userini.value
        if(userinis.match(/^([a-zA-Z.,]+\s)*[a-zA-Z.,]+$/g)){
          userini.setCustomValidity('');
          userini.reportValidity();
          event.preventDefault()
          // console.log(event)
          // console.log(event.target.name)
          // console.log(event.target.value)
          this.setState({
            [event.target.name]: event.target.value
          })
        }
        else{
          userini.setCustomValidity('Username Hanya Boleh Menggunakan Huruf dan 1 Spasi');
          userini.reportValidity();
        }
      }

        handleSubmit = async (event) => {
          event.preventDefault()
          const idus = this.props.match.params.id
          await API.post('/user/entity/lp/updet/up' + key.baseURL + '&id=' + idus, this.state)
            .then(Response =>
              this.setState({
                filedatas: Response.data[0].filedatas
              })
            )
      
          if (this.state.filedatas == 1) {
            alert("Berhasil !")
            window.location.replace('/administrator/kelolauser')
          } else {
            alert("Ada data sudah terdaftar, Mohon Periksa Kembali !")
          }
      }

    render() {

      function validasiNIDN() {
          var nidn = document.getElementById('nidn');
          var panjangnidn = nidn.value.length;
    
          if (panjangnidn > 0 && panjangnidn < 8 ) {
            document.getElementById('3').innerHTML = 'Karakter Harus Berjumlah lebih dari 8';
            document.getElementById('31').innerHTML = '';
          } else {
            document.getElementById('3').innerHTML = '';
            document.getElementById('31').innerHTML = 'NIDN sudah sesuai';
          }
    
      }
      function validasiPassword(){
          var password = document.getElementById('password').value;
          var passnya = password.length;
          
          if(passnya < 8){
            document.getElementById('2').innerHTML = 'Password Minimal 8 Karakter';
            document.getElementById('21').innerHTML = '';
          }else{
            document.getElementById('2').innerHTML = '';
            document.getElementById('21').innerHTML = 'Password sudah sesuai';
          }
      }
      function validasiUsername(){
          var userini = document.getElementById('username').value;
          if(userini.match(/^([a-zA-Z]+[a-zA-Z,.]+\s)*[a-zA-Z.,]+$/g)){
            document.getElementById('1').innerHTML = '';
            document.getElementById('11').innerHTML = 'Username sudah sesuai';
          }
          else if (userini < 1) {
            document.getElementById('1').innerHTML = 'Username Belum Diisi';
            document.getElementById('11').innerHTML = '';
          }
          else{
            document.getElementById('1').innerHTML = 'Username Hanya Boleh Menggunakan Huruf dan 1 Spasi';
            document.getElementById('11').innerHTML = '';
          }
    
      }
        
        return (
            <div>
                <Menu />
                <Header />
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <h1>Edit User</h1>
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 margin-bottom-30">
                                <div className="box box-primary box-solid">
                                    <div className="box-header">
                                        <strong>Form Edit User</strong>
                                        <div className="box-tools pull-right">
                                        </div>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="box-body">
                                    <form onSubmit={this.handleSubmit}>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>User ID</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-key"></i>
                                                        </div>
                                                        <input type="text" name="user_id" required className="form-control" defaultValue={this.state.user_id} onChange={this.handleInputChange} placeholder="User ID"></input>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Nama Pengguna</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <input onKeyUp={validasiUsername} type="text" id="username" name="user_name" required className="form-control" defaultValue={this.state.user_name} onChange={this.handleUser} placeholder="User Name"></input>
                                                    </div>
                                                </div>
                                                <div id="1" style={{ color: 'red' }}></div>
                                                <div id="11" style={{ color: 'green' }}></div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Tanggal Masuk</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  <input type="date" id="tgl_masuk" required name="tgl_masuk" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.tgl_masuk} placeholder="Tanggal Masuk"></input>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Tanggal Keluar</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  <input type="date" id="tgl_keluar" name="tgl_keluar" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.tgl_keluar} placeholder="Tanggal Keluar"></input>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Password Default</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-lock"></i>
                                                        </div>
                                                        <input onKeyUp={validasiPassword} type="password" id="password" required name="user_pass_def" className="form-control" defaultValue={this.state.user_pass_def} onChange={this.handlePass} placeholder="Password"></input>
                                                    </div>
                                                </div>
                                                <div id="2" style={{ color: 'red' }}></div>
                                                <div id="21" style={{ color: 'green' }}></div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Alamat</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  <input type="text" id="alamat" required name="alamat" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.alamat} placeholder="Alamat"></input>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>No HP</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  <input type="number" id="no_hp" required name="no_hp" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.no_hp} placeholder="No HP"></input>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>NIDN</label>
                                                    <div className="input-group">
                                                        <div className="input-group-addon">
                                                            <i className="fa fa-clipboard"></i>
                                                        </div>
                                                        <input onKeyUp={validasiNIDN} id="nidn" onInput={(e) => { e.target.value = e.target.value.slice(0, 12) }} type="number" name="nidn" className="form-control" defaultValue={this.state.nidn} onChange={this.handleNIDN} placeholder="NIDN" ></input>
                                                    </div>
                                                </div>
                                                <div id="3" style={{ color: 'red' }}></div>
                                                <div id="31" style={{ color: 'green' }}></div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Posisi 1</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  {/* <input type="text" id="posisi1" required name="posisi1" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.posisi1} placeholder="Posisi 1"></input> */}
                                                  <select type="text" id="posisi1" required name="posisi1" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.posisi1} placeholder="Posisi 1">
                                                    <option value="">--Posisi 1--</option>
                                                    <option value="Dosen FTD">Dosen FTD</option>
                                                    <option value="Dosen FEB">Dosen FEB</option>
                                                    <option value="Karyawan">Karyawan</option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Posisi 2</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  <input type="text" id="posisi2" required name="posisi2" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.posisi2} placeholder="Posisi 2"></input>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-6">
                                              <div className="form-group">
                                                <label>Jabatan</label>
                                                <div className="input-group">
                                                  <div className="input-group-addon">
                                                    <i className="fa fa-lock"></i>
                                                  </div>
                                                  <input type="text" id="jabatan" name="jabatan" className="form-control" onChange={this.handleInputChange} defaultValue={this.state.jabatan} placeholder="Jabatan"></input>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <Link to="/administrator/kelolauser" className="btn btn-warning">Kembali</Link> &nbsp;
                                                <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /.box-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </section>
                    {/* /.content */}
                </div>
                <Footer />
            </div>
        );
    }
}
