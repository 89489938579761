import React, { Component } from 'react'
import key from '../../axios/key'
import API from '../../axios/Api'

// async function handleClick() {
//  const asd =  await API.get('cari/id/user/c' + key.baseURL)
//   console.log(asd)
// }
 
function V_LogSurat({ v_logsurat }) {
  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);

  // const status = async (val) => {

  //   await API.get('cari/id/user/seleketep/view' + key.baseURL + '&idus=' + v_logsurat.id)

  //   // console.log(val)

  //   // if (val === 0) return 'Dikirim'

  //   // else return 'Disimpan'

  // }

  return (
    <tr>
      <td>{v_logsurat.No}</td>
      <td>{v_logsurat.user_name}</td>
      <td>{v_logsurat.Log_date}</td>
      <td>{v_logsurat.Log_action}</td>
    </tr>
  )
}
export default V_LogSurat