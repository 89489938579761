import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Menu from './pages/administrator/Menu'

// import Coba from './pages/Coba'

//AUTH
import Loading from './pages/Loading'
import Loadingout from './pages/Loadingout'
import Editdataakses from './pages/administrator/EditDataAkses'

//LEMBAGA
// import Lembaga_Home from './pages/lembaga/Home'
// import Lembaga_Emergency from './pages/lembaga/Emergency'
// import Lembaga_Surat from './pages/lembaga/Surat'
// import Lembaga_DistribusiSuratKeluar from './pages/lembaga/DistribusiSuratKeluar'
// import Lembaga_Undangan from './pages/lembaga/Undangan'
// import Lembaga_Notulen from './pages/lembaga/Notulen'
// import Lembaga_NotulenIsi from './pages/lembaga/NotulenIsi'
// import Lembaga_NotulenEdit from './pages/lembaga/NotulenEdit'
// import Lembaga_Notif_ada_notulen from './pages/lembaga/Notif_ada_Notulen'
// import Lembaga_Notif_tidakada_notulen from './pages/lembaga/Notif_tidakada_Notulen'
// import Lembaga_Notif_ReqSurat from './pages/Notif_ReqSurat'
// import Lembaga_History_SuratUndangan from './pages/History_SuratUndangan'




// //SEKRETARIAT
import Notfound from './pages/NotFound'
// import Home from './pages/Home'
// import DistribusiSurat from './pages/DistribusiSurat'
// import DistribusiSuratKeluar from './pages/DistribusiSuratKeluar'
// import Surat from './pages/Surat'
// import SuratEdit from './pages/SuratEdit'
// import TerimaSurat from './pages/TerimaSurat'
// import Undangan from './pages/Undangan'
// import Notulen from './pages/Notulen'
// import NotulenIsi from './pages/NotulenIsi'
// import NotulenEdit from './pages/NotulenEdit'
// import Emergency from './pages/Emergency'
import Login from './pages/Login'
// import Notif from './pages/Notif_tidakada_Notulen'
// import Notif_ada_Notulen from './pages/Notif_ada_Notulen'
// import Notif_tidakada_Notulen from './pages/Notif_tidakada_Notulen'
// import Notif_ReqSurat from './pages/Notif_ReqSurat'
// import History_SuratUndangan from './pages/History_SuratUndangan'


//ADMINISTRATOR
import Beranda from './pages/administrator/Beranda'
import KelolaUser from './pages/administrator/KelolaUser'
import TambahUser from './pages/administrator/TambahUser'
import KelolaMenuSystem from './pages/administrator/KelolaMenuSystem'
import KelolaSubMenu from './pages/administrator/KelolaSubMenu'
import KelolaSubMenu2 from './pages/administrator/KelolaSubMenu2'
import LogBAA from './pages/administrator/LogBAA'
import LogBAU from './pages/administrator/LogBAU'
import LogSurat from './pages/administrator/LogSurat'
import KelolaRule from './pages/administrator/KelolaRule'
import SistemMenu from './pages/administrator/SistemMenu'
import GroupMenu from './pages/administrator/GroupMenu'
import SubMenu from './pages/administrator/SubMenu'
import Appmenu from './pages/administrator/AplikasiMenu'
import T_sistemmenu from './pages/administrator/T_sistemmenu'
import T_sistemmenu2 from './pages/administrator/T_sistemmenu2'
import Editusergroup2 from './pages/administrator/Editusergroup2'
import EditSisMenu from './pages/administrator/EditSistemMenu'
import T_aplikasimenu from './pages/administrator/T_aplikasimenu'
import T_submenu from './pages/administrator/T_submenu'
import T_groupmenu from './pages/administrator/T_groupmenu'
import EditAppMenu from './pages/administrator/EditAplikasiMenu'
import EditGMenu from './pages/administrator/EditGrupMenu'
import EditSubMenu from './pages/administrator/EditSubMenu'
import UserGroup from './pages/administrator/usergroup'
import UserGroup2 from './pages/administrator/usergroup2'
import TambahUserGroup from './pages/administrator/T_usergroup'
import TambahUserGroup2 from './pages/administrator/T_usergroup2'
import EditUserGroup from './pages/administrator/EditUserGroup'
import SettingAkses from './pages/administrator/SettingAkses'
import EditSMenu from './pages/administrator/EditSMenu'

import akses from './pages/administrator/akses'

import UserAccess from './pages/administrator/UserAccess'
import rektorat from './pages/administrator/rektorat'
import StrukturalJabatan from './pages/administrator/StrukturalJabatan'
import EditUser from './pages/administrator/EditUser'
import EditGrupAkses from './pages/administrator/EditGrupAkses'
import T_Struktural from './pages/administrator/T_Struktural'
import Editstruktural from './pages/administrator/Editstruktural'

//pdf
// import Edaran from './pdf/Edaran'
// import Kerjasama from './pdf/Kerjasama'
// import Kuasa from './pdf/Kuasa'
// import Lain from './pdf/Lain'
// import Keputusan from './pdf/Keputusan'
// import Keterangan from './pdf/Keterangan'
// import Tugas from './pdf/Tugas'
// import Peringatan from './pdf/Peringatan'
// import Kegiatan from './pdf/Kegiatan'
// import Ketetapan from './pdf/Ketetapan'
// import Perintah from './pdf/Perintah'
// import Pengumuman from './pdf/Pengumuman'
// import S_Undangan from './pdf/S_Undangan'

function App() {

  return (
    <Switch>

      {/*LOGIN*/}
      <Route path="/" exact component={Login} />

      {/*SEKRETRIAT*/}
      <Route path="/loading/:un" exact component={Loading} />
      <Route path="/loadingout" exact component={Loadingout} />

      {/* <Route path="/coba" exact component={Coba} /> */}

      {/* LEMBAGA */}
      {/* <Route path="/lembaga/home" exact component={Lembaga_Home} />
        <Route path="/lembaga/emergency" exact component={Lembaga_Emergency} />
        <Route path="/lembaga/surat" exact component={Lembaga_Surat} />
        <Route path="/lembaga/distribusisuratkeluar" exact component={Lembaga_DistribusiSuratKeluar} />
        <Route path="/lembaga/undangan" exact component={Lembaga_Undangan} />
        <Route path="/lembaga/notulen" exact component={Lembaga_Notulen} />
        <Route path="/lembaga/notulenisi" exact component={Lembaga_NotulenIsi} />
        <Route path="/lembaga/notulenedit" exact component={Lembaga_NotulenEdit} />
        <Route path="/lembaga/notif_tidakada_notulen" exact component={Lembaga_Notif_tidakada_notulen} />
        <Route path="/lembaga/notif_ada_notulen" exact component={Lembaga_Notif_ada_notulen} />
        <Route path="/lembaga/notif_reqsurat" exact component={Lembaga_Notif_ReqSurat} />
        <Route path="/lembaga/history_suratundangan" exact component={Lembaga_History_SuratUndangan} /> */}
      {/* LEMBAGA */}

      {/*SEKRETRIAT*/}
      {/* <Route path="/home" exact component={Home} />
        <Route path="/distribusisurat" exact component={DistribusiSurat} />
        <Route path="/surat" exact component={Surat} />
        <Route path="/suratedit/:Sent_code" exact component={SuratEdit} />
        <Route path="/terimasurat" exact component={TerimaSurat} />
        <Route path="/distribusisuratkeluar" exact component={DistribusiSuratKeluar} />
        <Route path="/undangan" exact component={Undangan} />
        <Route path="/notulen" exact component={Notulen} />
        <Route path="/notulenisi" exact component={NotulenIsi} />
        <Route path="/notulenedit" exact component={NotulenEdit} />
        <Route path="/emergency" exact component={Emergency} />
        <Route path="/notif" exact component={Notif} />
        <Route path="/notif_ada_notulen" exact component={Notif_ada_Notulen} />
        <Route path="/notif_tidakada_notulen" exact component={Notif_tidakada_Notulen} />
        <Route path="/notif_reqsurat" exact component={Notif_ReqSurat} />
        <Route path="/history_suratundangan" exact component={History_SuratUndangan} /> */}

      {/*ADMINISTRATOR*/}
      <Route path="/administrator/beranda" exact component={Beranda} />
      <Route path="/administrator/kelolauser" exact component={KelolaUser} />
      <Route path="/administrator/tambahuser" exact component={TambahUser} />
      <Route path="/administrator/kelolamenusystem" exact component={KelolaMenuSystem} />
      <Route path="/administrator/kelolasubmenu/:id_urt" exact component={KelolaSubMenu} />
      <Route path="/administrator/kelolasubmenu2/:grub_id" exact component={KelolaSubMenu2} />
      <Route path="/administrator/rektorat/:id_urt" exact component={rektorat} />
      <Route path="/administrator/logbaa" exact component={LogBAA} />
      <Route path="/administrator/logbau" exact component={LogBAU} />
      <Route path="/administrator/logsurat" exact component={LogSurat} />
      <Route path="/administrator/kelolarule" exact component={KelolaRule} />
      
      {/* <Route path="/administrator/sistemmenu" exact component={SistemMenu} /> */}
      <Route path="/administrator/sistemmenu/:tab_id" exact component={SistemMenu} />

      <Route path="/administrator/T_sistemmenu" exact component={T_sistemmenu} />
      <Route path="/administrator/T_sistemmenu2" exact component={T_sistemmenu2} />
      <Route path="/administrator/T_aplikasimenu" exact component={T_aplikasimenu} />
      <Route path="/administrator/T_groupmenu" exact component={T_groupmenu} />
      <Route path="/administrator/T_submenu" exact component={T_submenu} />
      <Route path="/administrator/groupmenu" exact component={GroupMenu} />
      <Route path="/administrator/submenu" exact component={SubMenu} />
      <Route path="/administrator/appmenu" exact component={Appmenu} />
      <Route path="/administrator/aksesuser/:id_user" exact component={Editdataakses} />
      <Route path="/administrator/editappmenu/:aplikasi_id" exact component={EditAppMenu} />
      <Route path="/administrator/editgrupmenu/:menu_id" exact component={EditGMenu} />
      <Route path="/administrator/editsubmenu/:menu_id" exact component={EditSubMenu} />
      <Route path="/administrator/editsistemmenu/:level_id" exact component={EditSisMenu} />
      <Route path="/administrator/editsmenu/:level_id" exact component={EditSMenu} />
      <Route path="/administrator/usergroup" exact component={UserGroup} />
      <Route path="/administrator/usergroup2" exact component={UserGroup2} />
      <Route path="/administrator/T_usergroup" exact component={TambahUserGroup} />
      <Route path="/administrator/T_usergroup2" exact component={TambahUserGroup2} />
      <Route path="/administrator/editusergroup2/:grub_id" exact component={Editusergroup2} />
      <Route path="/administrator/editusergroup/:grub_id" exact component={EditUserGroup} />
      <Route path="/administrator/useraccess" exact component={UserAccess} />
      <Route path="/administrator/settingakses/:id" exact component={SettingAkses} />
      <Route path="/administrator/strukturaljabatan" exact component={StrukturalJabatan} />
      <Route path="/administrator/tambahStruktural" exact component={T_Struktural} />
      <Route path="/administrator/editStruktural/:id_rektor" exact component={Editstruktural} />
      <Route path="/administrator/edituser/:id" exact component={EditUser} />
      <Route path="/administrator/editgrupakses/:id" exact component={EditGrupAkses} />

      <Route path="/administrator/akses" exact component={akses} />
      {/*pdf*/}
      {/* <Route path="/edaran" exact component={Edaran} />
        <Route path="/kerjasama" exact component={Kerjasama} />
        <Route path="/kuasa" exact component={Kuasa} />
        <Route path="/lain" exact component={Lain} />
        <Route path="/keputusan" exact component={Keputusan} />
        <Route path="/keterangan" exact component={Keterangan} />
        <Route path="/tugas" exact component={Tugas} />
        <Route path="/peringatan" exact component={Peringatan} />
        <Route path="/kegiatan" exact component={Kegiatan} />
        <Route path="/ketetapan" exact component={Ketetapan} />
        <Route path="/perintah" exact component={Perintah} />
        <Route path="/pengumuman" exact component={Pengumuman} />
        <Route path="/s_undangan" exact component={S_Undangan} /> */}

      <Route component={Notfound} />
    </Switch>
  )
}

export default App