import React, { Component } from 'react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import V_Userakses from './V_UserAccess'
import API from '../../axios/Api'
import key from '../../axios/key'
import { Link } from 'react-router-dom'

class UserAccess extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Recieve_code: '',
      Recieve_number: '',
      objek: [],


    }
  }

  componentDidMount() {
    this.userakses();
  }
  async userakses() {
    await API.get("usr/access/lz/view" + key.baseURL)
      .then(Response => this.setState({
        objek: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))
    console.log(this.state.objek)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {
    const renderData = this.state.objek.map(objek => {
      return (
        <V_Userakses V_uakses={objek} key={objek.grub_id} />
      )
    })
    // const script = document.createElement("script");

    // script.src = '../js/content.js';
    // script.async = true;

    // document.body.appendChild(script);
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>
              User Access
                        </h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>User Group System</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <div className="col-md-12">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>User Group</th>
                              <th>Setting Level Detail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderData}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>No</th>
                              <th>User Group</th>
                              <th>Setting Level Detail</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default UserAccess;