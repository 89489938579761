import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import acc from './acc'

export default class akses extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Recieve_code: '',
      Recieve_number: '',
      sistemmenu: [],


    }
  }

  componentDidMount() {
    this.sistemmenuf();
  }
  async sistemmenuf() {
    await API.get("menusis/tari/admin/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
      .then(Response => this.setState({
        sistemmenu: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    console.log(this.state.sistemmenu)

    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {

    const renderData = this.state.sistemmenu.map(sistemmenu => {
      return (
        <tr>
          <td>{sistemmenu.No}</td>
          <td>{sistemmenu.level_name}</td>
          <td>
            <div className="col-md-3">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Aplikasi Keuangan</h3>
                  <div className="box-tools pull-right">
                    <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times" /></button>
                  </div>
                  {/* /.box-tools */}
                </div>
                {/* /.box-header */}
                <div className="box-body">
                  The body of the box
                </div>
                {/* /.box-body */}
              </div>
              {/* /.box */}
            </div>

            <div className="card-body">
              {Object.values(sistemmenu.woy).map(sistemmenu =>

                <div className="alert alert-danger col-md-12" style={{ width: 'fit-content', marginRight: '10px' }}>
                  <div className="box-tools pull-right">
                    <button className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                  </div>
                  {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true" style={{ color: 'white' }}>×</button> */}
                  <h5>
                    {sistemmenu.nama_aplikasi}
                  </h5>
                </div>
              )}

            </div>
          </td>
          <td style={{ textAlign: 'center' }}>
            <button className="btn btn-warning" style={{ marginRight: '5px' }}>Edit</button>
            <button className="btn btn-danger">Delete</button>
          </td>
        </tr>
        // <V_sistemmenu V_sismenu={sistemmenu} key={sistemmenu.level_id} />
      )
    })
    return (
      <div className="wrapper">
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Sistem Menu</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>Sistem Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="table-responsive">
                      <div className="col-md-12">
                        <table id="example1" className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Menu Sistem</th>
                              <th>Aplikasi</th>
                              <th>Opsi</th>
                            </tr>
                          </thead>
                          <tbody>
                            {renderData}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-12 text-right" style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Link to={'T_sistemmenu'} className="btn btn-primary" style={{ marginLeft: '10px', marginTop: '20px', float: 'right' }}>Tambah </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
