import React from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'

function V_groupmenu({ V_gmenu }) {

  const script = document.createElement("script");

  script.src = '../js/tablemu.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {
    await API.put('grub/menu/hapus/wae/dl' + key.baseURL + '&menu_id=' + V_gmenu.menu_id)
    window.location.replace('/administrator/sistemmenu/group')
  }
  function pop() {
    var pop = window.confirm('Yakin ingin menghapus?')
    if (pop == true) {
      handleClick()
    } else {
      // window.location.reload()
    }
  }

  async function actf() {
    await API.put('/aktf/grub/menu/yoy/dl' + key.baseURL + '&menu_id=' + V_gmenu.menu_id)
    window.location.replace('/administrator/sistemmenu/group')
  }

  const sts = (val) => {
    if (val === "1" ) return <td style={{ textAlign: 'center' }}>
      <Link to={'/administrator/editgrupmenu/' + V_gmenu.menu_id} style={{ marginRight: '5px' }} className="btn btn-warning">Edit</Link>
      <button onClick={pop} className="btn btn-danger">Delete</button>
    </td>
    else return <td style={{ textAlign: 'center' }}>
      <button onClick={actf} className="btn btn-danger">Aktifkan</button>
    </td>
    
  }

  const ckye = (val) => {
    if (val == "1" ) return sts(V_gmenu.id_hidden)
    else return ''
  }

  return (
    <tr>
      <td>{V_gmenu.No}</td>
      <td>{V_gmenu.menu_id}</td>
      <td>{V_gmenu.menu_name}</td>
      <td>{V_gmenu.nama_aplikasi}</td>
      {ckye(V_gmenu.grub_hidden)}
    </tr>
  )
}

export default V_groupmenu;