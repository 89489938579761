import React from "react";
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'

function NotFound() {
  return (
    <div>
      {/* <div className="content-wrapper"> */}

        {/* <section className="content-header">
              <h1>
                404 Error Page
              </h1>
            </section> */}
        {/* <section className="content"> */}
        <div className="error-page" style={{marginTop: '20%'}}>
          <h2 className="headline text-yellow"> 404</h2>
          <div className="error-content">
            <h3><i className="fa fa-warning text-yellow" /> Oops! Kamu mau kemana sayang</h3>
            <p>
              Mainnya jangan jauh jauh nanti aku kangen
              <br></br>
              Disini aja ya sayang. 
              <br></br>
              <Link to="/" >return to Home</Link>
            </p>
          </div>
        </div>
        {/* </section> */}
      </div>
    // </div>
  )
}

export default NotFound;