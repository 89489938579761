import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import V_akses from './V_akses'
import key from '../../axios/key'

export default class T_groupmenu extends Component {

  constructor() {
    super()
    this.state = {
      groupmenu: [],
      filedatas: '',
      link: '',
      menu_name: '',
      sub_menu_id: '',
    }
  }

  async grpmenu() {
    await API.get("menu/grub/caca/lv/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
      .then(Response => this.setState({
        groupmenu: Response.data
      }))
    // console.log(this.state.groupmenu)
  }

  componentDidMount() {
    this.grpmenu();
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('menu/sub/astor/add' + key.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/sistemmenu/sub')
    } else {
      alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
    }

  }

  render() {
    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Sub Menu System</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-8 col-sm-12 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Tambah Sub Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    {/* <form method="POST" action="http://192.168.2.181/apinew/public/menu/sub/astor/add?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt" encType="multipart/form-data"> */}
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group-row">
                        {/* start form group */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Nama Sub menu</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-sitemap"></i>
                              </div>
                              <input name="menu_name" className="form-control" required placeholder="Nama Sub Menu" onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Link Sub Menu</label>
                            <div className="input-group">
                              <div className="input-group-addon"><i className="fa fa-link"></i>
                              </div>
                              <input name="link" className="form-control" required placeholder="Link Sub Menu" onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Group menu</label>
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-users"></i>
                              </div>
                              <select name="sub_menu_id" className="form-control" placeholder="-- pilih group menu --" required onChange={this.handleInputChange}>
                                <option value="" disabled selected hidden>-- Pilih Group Menu --</option>
                                {
                                  this.state.groupmenu.map(groupmenu => {
                                    return <option value={groupmenu.menu_id}>{groupmenu.menu_name}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* end form group */}
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <Link to="/administrator/sistemmenu/sub" className="btn btn-danger" style={{ marginRight: '5px' }}>Kembali</Link>
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button>&nbsp;
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
