import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'


function V_KelolaUser({ v_kelolauser }) {


  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {
    await API.put('/nonakt/user/entity/dl' + key.baseURL + '&id=' + v_kelolauser.id)
    window.location.reload()
  }
  function pop() {
    var pop = window.confirm('Apakah ingin di non-aktifkan?')
    if (pop == true) {
      handleClick()
    } else {
      // window.location.reload()
    }
  }
  async function actf() {
    await API.put('/aktf/usr/entiti/dl' + key.baseURL + '&id=' + v_kelolauser.id)
    window.location.reload()
  }

  const hhdn = (val) => {
    if (val === "1") return <td style={{ textAlign: 'center' }}>
      <div className="row">
        <Link to={'/administrator/editgrupakses/' + v_kelolauser.id} className="btn btn-info kustomanku">Grup Akses</Link>
        {/* <Link to={'/administrator/edituser/' + v_kelolauser.id} className="btn btn-warning kustomanku">Edit</Link> */}
        {/* <a onClick={pop} className="btn btn-danger kustomanku" >Non-Aktif</a> */}
      </div>
    </td>
    else return <td style={{ textAlign: 'center' }}>
      {/* <a onClick={actf} className="btn btn-success kustomanku">Aktifkan</a> */}
    </td>
  }
  const ckyes = (val) => {
    if (val == "1") return <td align="center"><input type="checkbox" name="check_list[]" value={v_kelolauser.user_id}></input></td>
    else return <td />
  }

  const goks = (val) => {
    if (val == "1") return hhdn(v_kelolauser.id_hidden)
    else return <td align="center">
      <div className="row">
        {/* <Link to={'/administrator/editgrupakses/' + v_kelolauser.id} className="btn btn-info kustomanku">Grup Akses</Link> */}
        {/* <Link to={'/administrator/edituser/' + v_kelolauser.id} className="btn btn-warning kustomanku">Edit</Link> */}
      </div>
    </td>
  }

  return (
    <tr>
      <td>{v_kelolauser.No}</td>
      <td>{v_kelolauser.user_id}</td>
      <td>{v_kelolauser.user_name}</td>
      <td>{v_kelolauser.user_pass_def}</td>
      {/* <td>{v_kelolauser.nidn}</td> */}
      <td>
        <div className="card-body">
          {Object.values(v_kelolauser.user_grub).map(v_grub =>
            <div key={v_grub.id} className="badge badge-primary col-lg-4" style={{ marginRight: '150px', marginBottom: '5px', backgroundColor: '#4287f5', paddingBottom: '10px' }}>
              <h5 style={{ paddingTop: '7px' }}>
                {v_grub.nama_grup}
              </h5>
            </div>
          )}
        </div>
      </td>
      {ckyes(v_kelolauser.id_hidden)}
      {goks(v_kelolauser.on_off)}
      {/* <td align="center"><input type="checkbox" name="check_list[]" value={ckyes(v_kelolauser.user_id)}></input></td> */}
      {/* <td>
        <Link to={'/administrator/edituser/' + v_kelolauser.id} className="btn btn-info" style={{width: '90px'}}>Edit</Link> &nbsp;
        <button className="btn btn-success" style={{width: '90px'}}>Aktif</button> &nbsp;
        <button className="btn btn-danger" style={{width: '90px'}}>Non-Aktif</button>
      </td> */}
    </tr>
  )
}

export default V_KelolaUser