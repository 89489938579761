import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'
import V_groupmenu from './V_groupmenu.js'
import V_submenu from './V_SubMenu.js'

export default class SistemMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Recieve_code: '',
      Recieve_number: '',
      sistemmenu: [],
      groupmenu: [],
      supmenu: [],
    }
    this.tabid = props.match.params.tab_id
    // console.log(this.tabid)
  }

  componentDidMount() {
    // console.log(this.props);
    this.sistemmenuf();
    this.grpmenu();
    this.submenu();
  }

  async submenu() {
    await API.get("sub/grub/menu/lz/view" + key.baseURL)
      .then(Response =>
        this.setState({
          supmenu: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
        }))
    // console.log(this.state.supmenu)
  }

  async grpmenu() {
    await API.get("menu/grub/caca/lv/view" + key.baseURL)
      .then(Response => this.setState({
        groupmenu: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))
    // console.log(this.state.groupmenu)
    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  async sistemmenuf() {
    await API.get("menusis/tari/admin/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
      .then(Response => this.setState({
        sistemmenu: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
      }))

    // console.log(this.state.sistemmenu)

    this.timer = setInterval(() => this.sesscek(), 1000);
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  dicobacuy() {
    return this.state.sistemmenu.map(sistemmenu => {

      const script = document.createElement("script");

      script.src = '../../js/content.js';
      script.async = true;

      document.body.appendChild(script);

      async function actf() {
        await API.put('/aktif/menu/sis/oke/dl' + key.baseURL + '&level_id=' + sistemmenu.level_id)
        window.location.reload()
      }

      async function handleClick() {
        await API.put('/menu/sistem/hapus/sip/dl' + key.baseURL + '&level_id=' + sistemmenu.level_id)
        window.location.replace('/administrator/sistemmenu/level')
      }

      function pop() {
        var pop = window.confirm('Yakin ingin menghapus?')
        if (pop == true) {
          handleClick()
        } else {
          // window.location.reload()
        }
      }

      const sts = (val) => {
        if (val == 1) return <td style={{ textAlign: 'center' }}>
          <Link to={'/administrator/editsmenu/' + sistemmenu.level_id} style={{ marginRight: '5px' }} className="btn btn-warning">Edit</Link>
          <Link to={'/administrator/editsistemmenu/' + sistemmenu.level_id} style={{ marginRight: '5px' }} className="btn btn-primary">App</Link>
          <button onClick={pop} className="btn btn-danger">Delete</button>
        </td>
        else return <td style={{ textAlign: 'center' }}>
          <button onClick={actf} className="btn btn-success">Aktifkan</button>
        </td>
      }

      return (
        <tr key={sistemmenu.level_id}>
          <td>{sistemmenu.No}</td>
          <td>{sistemmenu.level_name}</td>
          <td>
            <div className="card-body">
              {Object.values(sistemmenu.woy).map(sistemmenu =>
                <div key={sistemmenu.aplikasi_id} className="badge badge-primary col-lg-4" style={{ marginRight: '10px', marginBottom: '5px', backgroundColor: '#4287f5', paddingBottom: '10px' }}>
                  <h5 style={{ paddingTop: '7px' }}>
                    {sistemmenu.nama_aplikasi}
                  </h5>
                </div>
              )}

            </div>
          </td>
          {sts(sistemmenu.id_hidden)}
        </tr>
      )
    })
  }

  render() {

    const renderSub = this.state.supmenu.map(supmenu => {
      return (
        <V_submenu V_supmenu={supmenu} key={supmenu.menu_id} />
      )
    })

    const renderGroup = this.state.groupmenu.map(groupmenu => {
      return (
        <V_groupmenu V_gmenu={groupmenu} key={groupmenu.menu_id} />
      )
    })


    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Sistem Menu</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-12 col-sm-12 margin-bottom-30">
                <div className="box box-success box-solid">
                  <div className="box-header">
                    <strong>Sistem Menu</strong>
                    <div className="box-tools pull-right">
                    </div>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <div className="col-md-12">
                      {/* Custom Tabs */}
                      <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                          <li className={`${this.tabid == 'level' ? 'active' : ''}`}><a href="#level" data-toggle="tab">Level Menu</a></li>
                          <li className={`${this.tabid == 'group' ? 'active' : ''}`}><a href="#group" data-toggle="tab">Grup Menu</a></li>
                          <li className={`${this.tabid == 'sub' ? 'active' : ''}`}><a href="#sub" data-toggle="tab">Sub Menu</a></li>
                        </ul>
                        <div className="tab-content">
                          <div className={`tab-pane ${this.tabid == 'level' ? 'active' : ''}`} id="level">
                            <div className="table-responsive">
                              <div className="col-md-12">
                                <table id="example1" className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Menu Sistem</th>
                                      <th>Aplikasi</th>
                                      <th>Opsi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.dicobacuy()}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th>No</th>
                                      <th>Menu Sistem</th>
                                      <th>Aplikasi</th>
                                      <th>Opsi</th>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                              <div className="col-md-12 text-right" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <a href="/administrator/T_sistemmenu" className="btn btn-primary" style={{ marginLeft: '10px', marginTop: '20px', float: 'right' }}>Tambah Level</a>
                              </div>
                            </div>
                          </div>
                          {/* /.tab-pane */}
                          <div className={`tab-pane ${this.tabid == 'group' ? 'active' : ''}`} id="group">
                            <div className="table-responsive">
                              <div className="col-md-12">
                                <table id="tablemui" name="table2" className="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th>No</th>
                                      <th>Grup Menu Id</th>
                                      <th>Nama Grup Menu</th>
                                      <th>Aplikasi</th>
                                      <th>Opsi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {renderGroup}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th>No</th>
                                      <th>Grup Menu Id</th>
                                      <th>Nama Grup Menu</th>
                                      <th>Aplikasi</th>
                                      <th>Opsi</th>
                                    </tr>
                                  </tfoot>
                                </table>
                                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                  <a href="/administrator/T_groupmenu" className="btn btn-primary" style={{ marginLeft: '10px', marginTop: '20px', float: 'right' }}>Tambah </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.tab-pane */}
                          <div className={`tab-pane ${this.tabid == 'sub' ? 'active' : ''}`} id="sub">
                            <div className="table-responsive">
                              <table id="tablemui2" className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th>No</th>
                                    <th>Sub Menu Id</th>
                                    <th>Sub Menu</th>
                                    <th>Link</th>
                                    <th>Grup Menu</th>
                                    <th>Opsi</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {renderSub}
                                </tbody>
                              </table>
                              <div>
                                <a href="/administrator/T_submenu" className="btn btn-primary" style={{ marginLeft: '10px', marginTop: '20px', float: 'right' }}>Tambah </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.box-body */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div>
    )
  }
}
