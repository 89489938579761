import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import API from '../../axios/Api'
import key from '../../axios/key'

export default class T_sistemmenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      level_name: '',
      filedatas: '',
      dataplikasi: [],
      aplikasi_id: '',
    }
  }

  handleInputChange = (event) => {
    event.preventDefault()
    // console.log(event)
    // console.log(event.target.name)
    // console.log(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    await API.post('menu/sistem/administrator/add' + key.baseURL, this.state)
      .then(Response =>
        this.setState({
          filedatas: Response.data[0].filedatas
        })
      )

    if (this.state.filedatas == 1) {
      alert("Berhasil !!")
      window.location.replace('/administrator/sistemmenu/level')
    } else {
      alert("Ada data yang sudah terdaftar, Mohon Periksa Kembali !")
    }

  }

  async AplikasiMenu() {
    // await API.get("aplikasi/admin/wkwk/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
    //   .then(Response => this.setState({
    //     dataplikasi: Response.data
    //   }))
    // console.log(this.state.dataplikasi)
  }

  async componentDidMount() {
    await API.get('aplikasi/admin/wkwk/view' + key.baseURL)
      // await API.get('test' + key.baseURL)
      .then(Response => this.setState({
        objek: Response.data
      }))
    // console.log(this.state.level_list)
    this.timer = setInterval(() => this.sesscek(), 1000);
    this.AplikasiMenu()
  }

  sesscek() {
    if (sessionStorage.getItem("adm") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    } else if (localStorage.getItem("logus") == null) {
      // sessionStorage.clear()
      window.location.replace('/loadingout')
    }

  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {

    // const scriptku = document.createElement("script");

    // scriptku.src = '../js/multipleselect.js';
    // scriptku.async = true;

    // document.body.appendChild(scriptku);

    return (
      <div>
        <Menu />
        <Header />
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <h1>Sistem Menu</h1>
          </section>
          {/* Main content */}
          <section className="content">
            <div className="row">
              <div className="col-md-6 col-sm-8 margin-bottom-30">
                <div className="box box-primary box-solid">
                  <div className="box-header">
                    <strong>Tambah Sistem Menu</strong>
                  </div>
                  {/* /.box-header */}
                  <div className="box-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        {/* start form group */}
                        <div className="table-responsive">
                          <div className="col-md-12">
                              <div className="form-group">
                                <label>Nama Menu System</label>
                                <div className="input-group">
                                  <div className="input-group-addon">
                                    <i className="fa fa-user"></i>
                                  </div>
                                  <input type="text" name="level_name" className="form-control" required onChange={this.handleInputChange} placeholder="Nama Menu System"></input>
                                </div>
                              </div>
                            </div>
                          {/* <div className="col-md-12">
                            <div className="form-group" >
                              <label>Aplikasi</label>
                              <div className="input-group">
                                <div className="input-group-addon">
                                  <i className="fa fa-user"></i>
                                </div>
                                <select id="" name="aplikasi_id" className="form-control" onChange={this.handleInputChange}>
                                  {
                                    this.state.dataplikasi.map(dataplikasi => {
                                      return <option value={dataplikasi.aplikasi_id}>{dataplikasi.nama_aplikasi}</option>
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div> */}

                          
                          {/* <div className="col-sm-6">
                              <div className="box box-info box-solid">
                                <div className="box-header">
                                  <strong>Nama Aplikasi</strong>
                                  <div className="box-tools pull-right">
                                  </div>
                                </div>
                            {
                              this.state.dataplikasi.map(dataplikasi => {
                                  return (
                                  <div className="form-group" style={{ marginLeft: '12px', marginTop: '12px' }}>
                                    <div className="form-check">
                                    <input className="form-check-input" id={dataplikasi.aplikasi_id} name="aplikasi_id" type="checkbox" style={{ width: '20px', height: '20px', borderRadius: '5px', verticalAlign: 'middle'}} value={dataplikasi.aplikasi_id} onChange={this.handleInputChange} />
                                    <label for={dataplikasi.aplikasi_id} className="form-check-label" style={{ marginLeft: '10px' }}>{dataplikasi.nama_aplikasi}</label>
                                  </div>
                                </div>
                                )
                              })
                            }
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginTop: "-10px" }}>
                        <Link to="/administrator/sistemmenu/level" className="btn btn-danger">Kembali</Link> &nbsp;
                        <button onSubmit={this.handleSubmit} className="btn btn-primary">Simpan</button> &nbsp;
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
          {/* /.content */}
        </div>
        <Footer />
      </div >
    )
  }
}
