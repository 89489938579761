import React from 'react'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import key from '../../axios/key'


function V_submenu({ V_supmenu }) {

  const script = document.createElement("script");

  script.src = '../js/tablemu.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {
    await API.put('sub/menyu/hapus/dulu/dl' + key.baseURL + '&menu_id=' + V_supmenu.menu_id)
    window.location.replace('/administrator/sistemmenu/sub')
  }
  function pop() {
    var pop = window.confirm('Yakin ingin menghapus?')
    if (pop == true) {
      handleClick()
    } else {
      // window.location.reload()
    }
  }

  async function actf() {
    await API.put('/aktifk/sub/menu/dulu/dl' + key.baseURL + '&menu_id=' + V_supmenu.menu_id)
    window.location.replace('/administrator/sistemmenu/sub')
  }

  const sts = (val) => {
    if (val === "1") return <td style={{ textAlign: 'center' }}>
      <Link to={'/administrator/editsubmenu/' + V_supmenu.menu_id} style={{ marginRight: '5px' }} className="btn btn-warning">Edit</Link>
      <button onClick={pop} className="btn btn-danger">Delete</button>
    </td>
    else return <td style={{ textAlign: 'center' }}>
      <button onClick={actf} className="btn btn-danger">Aktifkan</button>
    </td>
  }

  const ckyes = (val) => {
    if (val == "1") return sts(V_supmenu.id_hidden)
    else return ''
  }

  return (
    <tr>
      <td>{V_supmenu.No}</td>
      <td>{V_supmenu.menu_id}</td>
      <td>{V_supmenu.menu_name}</td>
      <td>{V_supmenu.link}</td>
      <td>{V_supmenu.gmenu}</td>
      {ckyes(V_supmenu.groub_hidden)}
    </tr>
  )
}

export default V_submenu;