import React from 'react'
import API from '../../axios/Api'
import key from '../../axios/key'
import { Link } from 'react-router-dom'


function V_EditSistemMenu({ v_editSistemMenu }) {

  const script = document.createElement("script");

  script.src = '../js/content.js';
  script.async = true;

  document.body.appendChild(script);

  async function handleClick() {
    await API.put('aplikasi/di/hapus/sungguhan/dl' + key.baseURL + '&aplikasi_id=' + v_editSistemMenu.aplikasi_id)
    window.location.reload()
  }
  function pop() {
    var pop = window.confirm('Yakin ingin menghapus?')
    if (pop == true) {
      handleClick()
    } else {
      // window.location.reload()
    }
  }
  async function actf() {
    await API.put('/aktfikan/aplikasi/mantab/dl' + key.baseURL + '&aplikasi_id=' + v_editSistemMenu.aplikasi_id)
    window.location.reload()
  }

  async function tambah() {
    // const level_id = match.params.level_id
    await API.put("aktifkan/aps/menusis/add" + key.baseURL + '&level_id=' + v_editSistemMenu.lv_id + '&aplikasi_id=' + v_editSistemMenu.aplikasi_id)
    window.location.reload()
  }

  async function apus() {
    // const level_id = match.params.level_id
    await API.delete("delete/aps/menusis/dl" + key.baseURL + '&id_rule=' + v_editSistemMenu.id_rule)
    window.location.reload()
  }

  const buttons = (val) => {
    if (val == "0") return <button onClick={tambah} className="btn btn-success">Tambah App</button>
    else return <button onClick={apus} className="btn btn-danger">Hapus App</button>
  }

  


  return (
    <tr>
      <th>{v_editSistemMenu.nama_aplikasi}</th>
      <th style={{ textAlign: 'center' }}>
        {buttons(v_editSistemMenu.btn)}
      </th>
    </tr>
  )
}

export default V_EditSistemMenu;