import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import config from '../axios/koneksi'
import API from '../axios/Api'

export default class Menu extends Component {
  constructor() {
    super()
    this.state = {
      namau: ''
    }
  }

  render() {
    return (
      <div>
        <aside className="main-sidebar">
          {/* sidebar: style can be found in sidebar.less */}
          <section className="sidebar">
            {/* Sidebar user panel */}
            <div className="user-panel">
              <div className="pull-left image">
                <img src="https://i.ibb.co/t4fQLp5/Institut-Teknologi-dan-Bisnis-Asia-Malang-1080x1023.png" className="img-circle" alt="User" />
              </div>
              <div className="pull-left info">
                <h5 style={{color: 'white', textTransform: 'capitalize'}}>{sessionStorage.getItem("un")}</h5>
              </div>
              <i className="fa fa-circle" style={{color: 'green', marginTop: '25px', marginLeft: '14px', fontSize: '13.5px'}}> Online</i> 
            </div>
            {/* sidebar menu: : style can be found in sidebar.less */}
            <ul className="sidebar-menu" data-widget="tree">
              <li className="header">Navigasi Menu</li>
              {/*Home*/}
              <li><Link to="/home"><i className="fa fa-home" /> <span>Home</span></Link></li>
              {/*Home*/}
              {/*Surat Masuk*/}
              <li className="active treeview menu-open">
                <Link to="#">
                  <i className="fa fa-download" /> <span>Surat Masuk</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </Link>
                <ul className="treeview-menu">
                  <li><Link to="/terimasurat"><i className="fa fa-envelope" />Terima Surat</Link></li>
                  <li><Link to="/distribusisurat"><i className="fa fa-bullhorn" />Distribusi Surat</Link></li>
                </ul>
              </li>
              {/*Surat Masuk*/}
              {/*Surat Keluar*/}
              <li className="active treeview menu-open">
                <Link to="#">
                  <i className="fa fa-upload" /> <span>Surat Keluar </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </Link>
                <ul className="treeview-menu">
                  <li><Link to="/suratk"><i className="fa fa-envelope" />Buat Surat</Link></li>
                  <li><Link to="/bemergency"><i className="fa fa-envelope" /> <span>Surat Emergensi</span></Link></li>
                  {/* <li><Link to="/surat"><i className="fa fa-envelope" />Surat</Link></li>
                  <li><Link to="/undangan"><i className="fa fa-bookmark" />Undangan</Link></li> */}
                  {/* <li><Link to="/distribusisuratkeluar"><i className="fa fa-upload" />History Surat Keluar</Link></li> */}
                  <li><Link to="/hsuratkeluar/0"><i className="fa fa-upload" />History Surat Keluar</Link></li>
                  {/* <li><Link to=""><i className="fa fa-book" /> <span>Notulen Rapat</span></Link></li> */}
                </ul>
              </li>
              <li className="active treeview menu-open">
                <Link to="#">
                  <i className="fa fa-upload" /> <span>Setting </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </Link>
                <ul className="treeview-menu">
                  <li><Link to="/djenis"><i className="fa fa-book" /> <span>Daftar Jenis Surat</span></Link></li>
                  <li><Link to="/tjenis"><i className="fa fa-book" /> <span>Tambah Jenis Surat</span></Link></li>


                  {/* <li><Link to="/ptheme"><i className="fa fa-book" /> <span>Daftar Theme</span></Link></li>
                  <li><Link to="/ttheme"><i className="fa fa-book" /> <span>Tambah Theme</span></Link></li> */}


                </ul>
                {/* <Link to="/ptheme"><i className="fa fa-book" /> <span>Plan Themplate</span></Link> */}
              </li>
              {/*Surat Keluar*/}
              {/*Notulen Rapat*/}
              {/* <li><Link to="/notulen"><i className="fa fa-book" /> <span>Notulen Rapat</span></Link></li> */}
              {/*Notulen Rapat*/}
              {/*Emergency*/}
              {/* <li><Link to="/emergency"><i className="fa fa-heartbeat" /> <span>Emergency</span></Link></li> */}
              {/*Emergency*/}
              {/*LogOut*/}
              <li><Link to="/loadingout"><i className="fa fa-sign-out" /> <span>Log Out</span></Link></li>

              {/*LogOut*/}
            </ul>
          </section>
          {/* /.sidebar */}
        </aside>
      </div>

    )
  }
  rcc
}
