import React, { Component } from 'react';
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import API from '../../axios/Api'
import V_StrukturalJabatan from './V_StrukturalJabatan'

class StrukturalJabatan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Recieve_code: '',
            Recieve_number: '',
            struktural: [],
        }
    }

    componentDidMount() {
        this.appmenu();
    }
    async appmenu() {
        const script = document.createElement("script");

        script.src = '../../js/content.js';
        script.async = true;

        document.body.appendChild(script);

        // const idass = this.props.match.params.id
        const ez = await API.get("/struktural/jabatan/view?z=ZhSaMNJDvmIlQCtYqurMDSpsmxhy1qlt")
            .then(Response => this.setState({
                struktural: Response.data.map((val, idx) => ({ No: idx + 1, ...val }))
            }))
        // this.state.struktural = this.state.struktural.filter(m => m.user_name != this.state.struktural.user_name)
        // console.log(this.state.struktural)
        this.timer = setInterval(() => this.sesscek(), 1000);
    }

    sesscek() {
        if (sessionStorage.getItem("adm") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        } else if (localStorage.getItem("logus") == null) {
            // sessionStorage.clear()
            window.location.replace('/loadingout')
        }

    }

    componentWillUnmount() {
        this.timer = null;
    }
    render() {
        const renderDataStruktural = this.state.struktural.map(struktural => {
            return (
                <V_StrukturalJabatan V_strukjb={struktural} key={struktural.id} />
            )
        })
        return (
            <div>
                <Menu />
                <Header />
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <h1>
                            Struktural Jabatan
                        </h1>
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 margin-bottom-30">
                                <div className="box box-success box-solid">
                                    <div className="box-header">
                                        <strong>Purna Jabatan</strong>
                                        <div className="box-tools pull-right">
                                        </div>
                                    </div>
                                    {/* /.box-header */}
                                    <div className="box-body">
                                        <div className="table-responsive">
                                            <div className="col-md-12">
                                                <table id="example1" className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>NIDN</th>
                                                            <th>Nama Lengkap</th>
                                                            <th>Jabatan</th>
                                                            <th>Keterangan Jabatan</th>
                                                            {/* <th>Nama Rektor</th> */}
                                                            <th>Level Menu</th>
                                                            <th>Masa jabatan mulai</th>
                                                            <th>Masa jabatan berakhir</th>
                                                            {/* <th>Opsi</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {renderDataStruktural}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>NIDN</th>
                                                            <th>Nama Lengkap</th>
                                                            <th>Jabatan</th>
                                                            <th>Keterangan Jabatan</th>
                                                            {/* <th>Nama Rektor</th> */}
                                                            <th>Level Menu</th>
                                                            <th>Masa jabatan mulai</th>
                                                            <th>Masa jabatan berakhir</th>
                                                            {/* <th>Opsi</th> */}
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <Link to="/administrator/tambahStruktural" className="btn btn-primary" style={{ marginLeft: '10px', marginTop: '20px', float: 'right' }}>Tambah </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.box-body */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </section>
                    {/* /.content */}
                </div>
                <Footer />
            </div>
        );
    }
}

export default StrukturalJabatan;